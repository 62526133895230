import { useState } from "react";

import { useLanguage } from "../../../../../../language.context";
import { useException } from "../../../../../../exception.context";
import { useMessage } from "../../../../../../message.context";

import { useUser } from "../../../user.context";

import SelectCoverFromLibraryControl from "../createalbum/selectCoverFromLibrary.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [isWorking, setIsWorking] = useState(false);

  const handleSavePressed = async () => {
    try {
      setIsWorking(true);

      await userCtx.album.updateCover(
        ctx.updateCover.cover._id || ctx.updateCover.cover
      );

      messageCtx.showSuccess(languageCtx.getStringFromId("Cover changed"));
      ctx.popItem();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!ctx.updateCover.cover) return false;
    return true;
  };

  const handleSelectCoverClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("Select from library"), (ctx) => (
      <SelectCoverFromLibraryControl
        ctx={ctx}
        cover={ctx.updateCover.cover}
        setCover={ctx.updateCover.setCover}
      />
    ));
  };

  return (
    <>
      <div className="text-center p-4">
        <div className="fs-2">
          {languageCtx.getStringFromId("Select your new cover")}
        </div>
        <div>
          <div
            className="mt-4 cover clickable"
            onClick={handleSelectCoverClicked}
          >
            {ctx.updateCover.cover && (
              <img
                src={ctx.updateCover.cover.uri}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit:
                    ctx.updateCover.cover?.source === "db"
                      ? "contain"
                      : "cover",
                }}
              />
            )}
            {!ctx.updateCover.cover && (
              <div className="user-select-none">
                {languageCtx.getStringFromId("selectCover")}
              </div>
            )}
          </div>
        </div>

        <button
          className="btn btn-blue mt-4"
          onClick={handleSavePressed}
          disabled={!isFormOk()}
        >
          {languageCtx.getStringFromId("Save")}
        </button>
      </div>
    </>
  );
};
