import React, { createContext, useState, useContext, useEffect } from "react";

import HeaderControl from "../../../controls/header2.control";
import { Outlet, useNavigate } from "react-router-dom";
import { useApp } from "../app.context";

const nouserContext = createContext();

export const useNouser = () => {
  return useContext(nouserContext);
};

export const NouserContextProvider = () => {
  const navigate = useNavigate();

  const appCtx = useApp();

  const [name, setName] = useState("");
  const [processType, setProcessType] = useState("");
  const [termsOfServiceVersion, setTermsOfServiceVersion] = useState();
  const [privacyPolicyVersion, setPrivacyPolicyVersion] = useState();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (appCtx.user.get()) {
      navigate("/app/albums");
    }
  }, []);

  const initialValue = {
    name,
    setName,

    processType,
    setProcessType,

    termsOfServiceVersion,
    setTermsOfServiceVersion,

    privacyPolicyVersion,
    setPrivacyPolicyVersion,

    email,
    setEmail,
  };

  return (
    <>
      <HeaderControl />
      <nouserContext.Provider value={initialValue}>
        <Outlet />
      </nouserContext.Provider>
    </>
  );
};
