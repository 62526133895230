import environmentUtil from "./environment.util";

const url = environmentUtil.getValue(
  "https://api1.fotisima.mx",
  "https://api1.fotisima.mx",
  window.location.origin
  //"http://localhost:3000"
);

const serverUrl = `${url}/api/v1`;

//console.log("user serverUrl", serverUrl);

class ApiServerClient {
  #token = undefined;
  #language = "en";

  #getUrl = (path) => {
    const endpointUrl = `${serverUrl}${path}`;

    return endpointUrl;
  };

  getToken = () => {
    return this.#token;
  };

  #checkStatus = async (res) => {
    if (res.ok) {
      // res.status >= 200 && res.status < 300
      const json = await res.json();
      return json;
    } else if (res.status === 500) {
      const json = await res.json();
      throw json;
    } else {
      throw new Error(res.statusText);
    }
  };

  #post = async (path, authorization, body) => {
    const options = {
      method: "POST",
      headers: {
        "Accept-Language": this.#language,
        "Content-Type": "application/json",
      },
    };

    if (authorization) {
      options.headers.Authorization = "Bearer " + this.#token;
    }

    if (body) {
      options.body = JSON.stringify(body);
    }

    const endpointUrl = this.#getUrl(path);

    //console.log(endpointUrl);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    //console.log("result", result);

    return result;
  };

  #postMultipart = async (path, authorization, data) => {
    const options = {
      method: "POST",
      headers: {
        "Accept-Language": this.#language,
      },
    };

    if (authorization) {
      options.headers.Authorization = "Bearer " + this.#token;
    }

    if (data) {
      options.body = data;
    }

    const endpointUrl = this.#getUrl(path);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  // affiliate = {
  //   signin: async (email, password) => {
  //     const user = await this.#post("/affiliate/signin", false, {
  //       email,
  //       password,
  //     });

  //     this.#token = user.token;

  //     return user;
  //   },
  //   getGiftcards: async () => {
  //     const giftcards = await this.#post("/affiliate/getGiftcards", true);

  //     return giftcards;
  //   },
  // };

  language = {
    get: async (code) => {
      const data = await this.#post("/language/get", false, { code });

      return data;
    },
    getByCode: async (code) => {
      const data = await this.#post("/language/getByCode", false, { code });

      return data;
    },
  };

  user = {
    exists: async (email) => {
      const result = await this.#post("/user/exists", true, {
        email,
      });

      return result.exists;
    },
    sendSignupVerificationCode: async (email, language) => {
      await this.#post("/user/sendSignupVerificationCode", true, {
        email,
        language,
      });
    },
    signup: async (
      name,
      email,
      verificationCode,
      language,
      privacyPolicyVersion,
      termsOfServiceVersion
    ) => {
      const user = await this.#post("/user/signup", false, {
        name,
        email,
        verificationCode,
        language,
        privacyPolicyVersion,
        termsOfServiceVersion,
      });

      this.#token = user.token;

      return user;
    },
    signupAsGuest: async (
      name,
      language,
      privacyPolicyVersion,
      termsOfServiceVersion
    ) => {
      const user = await this.#post("/user/signupAsGuest", false, {
        name,
        language,
        privacyPolicyVersion,
        termsOfServiceVersion,
      });

      this.#token = user.token;

      return user;
    },
    sendRegisterGuestVerificationCode: async (email, language) => {
      await this.#post("/user/sendRegisterGuestVerificationCode", true, {
        email,
        language,
      });
    },
    registerGuest: async (userId, email, verificationCode, languageCode) => {
      const user = await this.#post("/user/registerGuest", false, {
        userId,
        email,
        verificationCode,
        languageCode,
      });

      this.#token = user.token;

      return user;
    },
    sendSigninVerificationCode: async (email, language) => {
      await this.#post("/user/sendSigninVerificationCode", true, {
        email,
        language,
      });
    },
    signinByEmailAndVerificationCode: async (email, verificationCode) => {
      const user = await this.#post(
        "/user/signinByEmailAndVerificationCode",
        false,
        {
          email,
          verificationCode,
        }
      );

      this.#token = user.token;

      return user;
    },
    signinByToken: async (token) => {
      const user = await this.#post("/user/signinByToken", false, {
        token,
      });

      this.#token = user.token;

      return user;
    },
    signout: async () => {
      await this.#post("/user/signout", true);

      this.#token = undefined;
    },
    updatePushToken: async (pushToken) => {
      await this.#post("/user/updatePushToken", true, { pushToken });
    },
    updateName: async (name) => {
      const user = await this.#post("/user/updateName", true, { name });
      return user;
    },
    updateLanguage: async (language) => {
      const user = await this.#post("/user/updateLanguage", true, { language });
      this.#language = language;
      return user;
    },
    delete: async () => {
      await this.#post("/user/delete", true);
      this.#token = undefined;
    },
  };

  album = {
    getDemoAlbum: async () => {
      const album = this.#post("/album/getDemoAlbum", true);

      return album;
    },
    create: async (
      name,
      cover,
      startDate,
      timeZone,
      packageId,
      //language
      receipt
    ) => {
      const payload = new FormData();
      const data = {
        name,
        startDate,
        timeZone,
        packageId,
        receipt,
        //language,
      };

      if (typeof cover === "string") {
        data.coverId = cover;
      } else {
        //if (typeof cover === "file") {
        payload.append("coverFile", cover);
      }

      payload.append("data", JSON.stringify(data));

      const album = this.#postMultipart("/album/create", true, payload);

      return album;
    },
    createFromGiftcard: async (
      name,
      cover,
      startDate,
      timeZone,
      giftcardCode
    ) => {
      const payload = new FormData();
      const data = {
        name,
        startDate,
        timeZone,
        giftcardCode,
      };

      if (typeof cover === "string") {
        data.coverId = cover;
      } else {
        //if (typeof cover === "file") {
        payload.append("coverFile", cover);
      }

      payload.append("data", JSON.stringify(data));

      const album = this.#postMultipart(
        "/album/createFromGiftcard",
        true,
        payload
      );

      return album;
    },
    updateName: async (albumId, name) => {
      const album = this.#post("/album/updateName", true, { albumId, name });

      return album;
    },
    updateCover: async (albumId, cover) => {
      const payload = new FormData();
      const data = { albumId };

      if (typeof cover === "string") {
        data.coverId = cover;
      } else {
        //if (typeof cover === "file") {
        payload.append("coverFile", cover);
      }

      payload.append("data", JSON.stringify(data));

      const album = this.#postMultipart("/album/updateCover", true, payload);

      return album;
    },
    updateStartDate: async (albumId, startDate, timeZone) => {
      const data = { albumId, startDate, timeZone };

      const album = this.#post("/album/updateStartDate", true, data);

      return album;
    },
    upgrade: async (albumId, packageId, receipt, language) => {
      await this.#post("/album/upgrade2", true, {
        albumId,
        packageId,
        receipt,
        language,
      });
    },
    getAll: async () => {
      const albums = await this.#post("/album/getAll", true);

      return albums;
    },
    getById: async (albumId) => {
      const album = await this.#post("/album/getById", false, { albumId });

      return album;
    },
    getByClientSecret: async (clientSecret) => {
      const album = await this.#post("/album/getByClientSecret", true, {
        clientSecret,
      });

      return album;
    },
    join: async (albumId) => {
      const album = await this.#post("/album/join", true, {
        albumId,
      });

      return album;
    },
    getUsers: async (albumId) => {
      const users = await this.#post("/album/getUsers", true, { albumId });

      return users;
    },
    getUser: async (albumId, userId) => {
      const user = await this.#post("/album/getUser", true, {
        albumId,
        userId,
      });

      return user;
    },
    updateUser: async (albumId, userId, blocked) => {
      await this.#post("/album/updateUser", true, {
        albumId,
        userId,
        blocked,
      });
    },
    getInviteCardsUrl: (albumId, language) => {
      const endpointUrl = this.#getUrl(
        `/album/inviteCard?albumId=${albumId}&la=${language}`
      );
      return endpointUrl;
    },
  };

  giftcard = {
    getPackage: async (code) => {
      const pkg = this.#post("/giftcard/getPackage", true, {
        code,
      });

      return pkg;
    },
  };

  package = {
    getProductIds: async () => {
      const productIds = await this.#post("/package/getProductIds", true);

      return productIds;
    },
    getAllByCurrencyCode: async (currencyCode) => {
      const packages = await this.#post(
        "/package/getAllByCurrencyCode",
        false,
        {
          currencyCode,
        }
      );

      return packages;
    },
    getStoreByIPLocation: async () => {
      const store = await this.#post("/package/getStoreByIPLocation", false);

      return store;
    },
  };

  stripe = {
    createCreateAlbumPaymentIntent: async (
      name,
      cover,
      startDate,
      timeZone,
      packageId,
      language
    ) => {
      const data = {
        name,
        startDate,
        timeZone,
        packageId,
        language,
      };

      const payload = new FormData();

      if (typeof cover === "string") {
        data.coverId = cover;
      } else {
        //if (typeof cover === "file") {
        payload.append("coverFile", cover);
      }

      payload.append("data", JSON.stringify(data));

      const response = await this.#postMultipart(
        "/stripe/createCreateAlbumPaymentIntent",
        true,
        payload
      );

      return response.clientSecret;
    },
    createUpgradeAlbumPaymentIntent: async (albumId, packageId) => {
      const response = await this.#post(
        "/stripe/createUpgradeAlbumPaymentIntent",
        true,
        {
          albumId,
          packageId,
        }
      );

      return response.clientSecret;
    },
  };

  cover = {
    getAll: async () => {
      const covers = await this.#post("/cover/getAll", true);

      return covers;
    },
  };

  media = {
    delete: async (mediaId) => {
      const album = await this.#post("/media/delete", true, {
        mediaId,
      });

      return album;
    },
    exists: async (mediaId) => {
      const result = await this.#post("/media/exists", true, {
        mediaId,
      });

      return result.exists;
    },
    getByAlbumId: async (albumId) => {
      const info = await this.#post("/media/getByAlbumId", true, { albumId });

      return info;
    },
    getChangesByAlbumId: async (albumId, timestamp) => {
      const info = await this.#post("/media/getChangesByAlbumId", true, {
        albumId,
        timestamp,
      });

      return info;
    },
  };

  termsOfService = {
    getById: async (termsOfServiceId) => {
      const termsOfService = await this.#post("/termsOfService/getById", true, {
        termsOfServiceId,
      });

      return termsOfService;
    },
    getByVersion: async (version, languageCode) => {
      const termsOfService = await this.#post(
        "/termsOfService/getByVersion",
        true,
        {
          version,
          languageCode,
        }
      );

      return termsOfService;
    },
    getLatest: async (languageCode) => {
      const termsOfService = await this.#post(
        "/termsOfService/getLatest",
        true,
        { languageCode }
      );

      return termsOfService;
    },
  };

  privacyPolicy = {
    getById: async (privacyPolicyId) => {
      const privacyPolicy = await this.#post("/privacyPolicy/getById", true, {
        privacyPolicyId,
      });

      return privacyPolicy;
    },
    getByVersion: async (version, languageCode) => {
      const privacyPolicy = await this.#post(
        "/privacyPolicy/getByVersion",
        true,
        {
          version,
          languageCode,
        }
      );

      return privacyPolicy;
    },
    getLatest: async (languageCode) => {
      const privacyPolicy = await this.#post("/privacyPolicy/getLatest", true, {
        languageCode,
      });

      return privacyPolicy;
    },
  };

  server = {
    getAvailableFileserver: async () => {
      const server = await this.#post("/server/getAvailableFileserver", true);

      return server;
    },
  };

  contactus = {
    create: async (name, email, phone, text) => {
      const server = await this.#post("/contactus/create", true, {
        name,
        email,
        phone,
        text,
      });

      return server;
    },
  };
}

export default ApiServerClient;
