import { useLanguage } from "../../../../../../language.context";

import { useUser } from "../../../user.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const userCtx = useUser();

  return (
    <div className="text-center p-4">
      <div className="fs-2">{languageCtx.getStringFromId("Info")}</div>

      <div>
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("name")}
        </label>
        <div>{userCtx.album.get().name}</div>
      </div>
      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Package")}
        </label>
        <div>
          {
            languageCtx.getPackageFromId(userCtx.album.get().package.productId)
              .name
          }
        </div>
      </div>

      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Invites")}
        </label>
        <div>{`${userCtx.album.get().users.length} / ${
          userCtx.album.get().package.inviteCount
        }`}</div>
      </div>
      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Photos/videos")}
        </label>
        <div>
          {`${
            userCtx.album.get().usedPhotoCount +
            userCtx.album.get().usedVideoCount
          } / ${userCtx.album.get().package.mediaCount}`}
        </div>
      </div>
    </div>
  );
};
