const util = {
  encodeComponent: (component) => {
    //const s = encodeURIComponent(component); // version 2022-12-01
    const s = encodeURIComponent(encodeURIComponent(component)); // version 2023-01-18
    return s;
  },
  isOldInviteCards: () =>
    /^\/invitecards\/[^\/\s]+$/i.test(window.location.pathname),
  isOldAppAlbumJoin: () =>
    /^\/app\/albums\/[^\/\s]+\/join$/i.test(window.location.pathname),
  isOldPresentAlbum: () =>
    /^\/app\/albums\/[^\/\s]+\/presentation$/i.test(window.location.pathname),
  isAppPresentAlbum: () =>
    /^\/app\/presentalbum\/[^\/\s]+$/i.test(window.location.pathname),
  isAppInviteCards: () =>
    /^\/app\/invitecards\/[^\/\s]+$/i.test(window.location.pathname),
  isAppJoinAlbum: () =>
    /^\/app\/joinalbum\/[^\/\s]+$/i.test(window.location.pathname),
  isAppPrivacyPolicy: () =>
    /^\/app\/privacypolicy\/\d{1,}$/i.test(window.location.pathname),
  isAppPayment: () => /^\/app\/payment$/i.test(window.location.pathname),
  isAppTermsOfService: () =>
    /^\/app\/termsofservice\/\d{1,}$/i.test(window.location.pathname),
  isAppJoinDemo: () => /^\/app\/joindemo$/i.test(window.location.pathname),
  isAppAlbum: () => /^\/app\/albums\/[^\/\s]+$/i.test(window.location.pathname),
};

export default util;
