import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { useException } from "../../../../exception.context";
import { useLanguage } from "../../../../language.context";
import TableControl from "../../../../controls/table.control";
import Modal from "../../../../controls/modal";
import SelectInput from "../../../../controls/select.input";

import { useAffiliateApp } from "../../app.context";

const range = (startAt, endAt) => {
  return [...Array(endAt - startAt + 1).keys()].map((i) => i + startAt);
};

export default () => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appCtx = useAffiliateApp();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [year, setYear] = useState(DateTime.now().year.toString());

  useEffect(() => {
    const refresh = async () => {
      try {
        const invoices = await appCtx.apiServerClient.affiliate.getInvoices(
          year
        );

        setInvoices(invoices);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  const handleFilterClicked = () => {
    setShowFilterModal(true);
  };

  const handleInvoiceClicked = (invoice) => {
    navigate(`./${invoice._id}`);
  };

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>{languageCtx.getStringFromId("Invoices")}</h3>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-blue" onClick={handleFilterClicked}>
            <FontAwesomeIcon icon={faFilter} />
          </button>
        </div>

        <TableControl
          columns={[
            {
              header: languageCtx.getStringFromId("date"),
              valueExtractor: (i) => DateTime.fromISO(i.createdAt).toISODate(),
            },
            {
              header: languageCtx.getStringFromId("Due"),
              valueExtractor: (i) => DateTime.fromISO(i.dueAt).toISODate(),
            },
            {
              header: languageCtx.getStringFromId("Paid"),
              valueExtractor: (i) =>
                i.paidAt ? DateTime.fromISO(i.paidAt).toISODate() : "",
            },
            {
              header: languageCtx.getStringFromId("Amount"),
              className: "text-end",
              valueExtractor: (i) =>
                `${(i.amountMicros / 1000000).toFixed(2)} ${
                  appCtx.affiliate.get().currencyCode
                }`,
            },
          ]}
          rows={invoices}
          keyExtractor={(i) => i._id}
          onRowClicked={handleInvoiceClicked}
        />

        <Modal
          show={showFilterModal}
          setShow={setShowFilterModal}
          title={languageCtx.getStringFromId("Filter")}
          closeButton={true}
        >
          <div className="p-4">
            <label className="form-label">
              {languageCtx.getStringFromId("Year")}
            </label>
            <SelectInput
              items={range(2024, DateTime.now().year)}
              valueExtractor={(i) => i}
              nameExtractor={(i) => i}
              value={year}
              onChange={setYear}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};
