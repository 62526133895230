import stringUtil from "../../../../../../../utils/string.util";

import { useLanguage } from "../../../../../../language.context";
import { useException } from "../../../../../../exception.context";
import { useMessage } from "../../../../../../message.context";
import CodeControl from "../../../../../../controls/code.control2";

import { useApp } from "../../../../app.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appCtx = useApp();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  // useEffect(() => {
  //   if (show) {
  //     setCodeSent(false);
  //     setEmail("");
  //     setVerificationCode("");
  //   }
  // }, [show]);

  const setVerificationCodeAdapter = (code) => {
    const code2 = code.replace(/\D/g, "").toUpperCase();
    ctx.registerUser.setVerificationCode(code2);
  };

  const handleSubmitPressed = async () => {
    try {
      ctx.setIsWorking(true);

      await appCtx.user.registerGuest(
        ctx.registerUser.email.trim(),
        ctx.registerUser.verificationCode,
        languageCtx.language.code
      );

      messageCtx.showSuccess(languageCtx.getStringFromId("User registered"));

      switch (ctx.registerUser.onSuccess) {
        case "pop3":
          ctx.popItem(3);
          break;
        case "pop2":
          ctx.popItem(2);
          break;
        case "close":
          ctx.setShow(false);
          break;
      }
      return;
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      ctx.setIsWorking(false);
    }
  };

  const isVerificationCodeFormOk = () => {
    if (ctx.registerUser.verificationCode.length != 4) return false;

    return true;
  };

  return (
    <div className="p-3 text-center">
      <div>
        <div>
          {stringUtil.formatString(
            languageCtx.getStringFromId(
              "Enter the code sent to your email xxx"
            ),
            ctx.registerUser.email
          )}
        </div>
        <div className="mt-3">
          <CodeControl
            inputMode="numeric"
            onSubmit={handleSubmitPressed}
            value={ctx.registerUser.verificationCode}
            onChange={setVerificationCodeAdapter}
            forceUppercase={true}
            filterChars={"0123456789".split("")}
            filterCharsIsWhitelist={true}
            fields={4}
            autoFocus={true}
            inputStyle={{
              padding: 0,
              marginLeft: 2,
              marginRight: 2,
              maxWidth: 65,
              maxHeight: 65,
              textAlign: "center",
              fontSize: 40,
              fontWeight: 700,
            }}
          />
        </div>
      </div>
      <div className="mt-3 text-center">
        <button
          className="btn btn-blue ms-1"
          onClick={handleSubmitPressed}
          disabled={!isVerificationCodeFormOk()}
        >
          {languageCtx.getStringFromId("Register")}
        </button>
      </div>
    </div>
  );
};
