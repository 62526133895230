import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import QRCode from "react-qr-code";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import errorUtil from "../../../utils/error.util";
import albumUtil from "../../../utils/album.util";
import validateUtil from "../../../utils/validate.util";

import stand2 from "../../../assets/stand3.png";
import folded from "../../../assets/Folded3.png";
import plain from "../../../assets/plain.png";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";
import HeaderControl from "../../controls/header2.control";
import FooterControl from "../../controls/footer.control";

import { useApp } from "./app.context";

export default () => {
  const params = useParams();

  const exceptionCtx = useException();
  const languageCtx = useLanguage();
  const appCtx = useApp();

  const qrCodeRef = useRef();
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [album, setAlbum] = useState();

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);

        if (!validateUtil.objectId.isValid(params.albumId)) {
          throw new Error("");
        }

        const album = await appCtx.apiServerClient.album.getById(
          params.albumId
        );

        setAlbum(album);
      } catch (ex) {
        if (
          errorUtil.isCustomError(ex) &&
          ex.error.id === "SERVER_ALBUM_NOT_FOUND"
        ) {
        } else {
          exceptionCtx.handleException(ex);
        }
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, []);

  const handleDownloadQrCodeClicked = (e) => {
    e.preventDefault();

    const svg = qrCodeRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const steps = [
    {
      title: languageCtx.getStringFromId("Download"),
      content: languageCtx.getStringFromId("Download your invite cards"),
    },
    {
      title: languageCtx.getStringFromId("Print"),
      content: languageCtx.getStringFromId(
        "Print on your homeprinter or at office max"
      ),
    },
    {
      title: languageCtx.getStringFromId("Share"),
      content: languageCtx.getStringFromId(
        "Cut, fold and place them on tables"
      ),
    },
  ];

  const inviteCardsUrl = appCtx.apiServerClient.album.getInviteCardsUrl(
    params.albumId,
    languageCtx.language.code
  );

  const actions = [
    {
      show: () => true,
      title: languageCtx.getStringFromId("Stand"),
      subtitleLanguageStringId: languageCtx.getStringFromId(
        'Cut out and place inside a 4" x 6" stand'
      ),
      url: inviteCardsUrl + "&type=stand",
      img: stand2,
    },
    {
      show: () => true,
      title: languageCtx.getStringFromId("Folded"),
      subtitleLanguageStringId: languageCtx.getStringFromId(
        "Cut out, fold and place directly on table"
      ),
      url: inviteCardsUrl + "&type=folded",
      img: folded,
    },
    {
      show: () => true,
      title: languageCtx.getStringFromId("Plain"),
      subtitleLanguageStringId: languageCtx.getStringFromId(
        "Cut out and place them on plates"
      ),
      url: inviteCardsUrl + "&type=plain",
      img: plain,
    },
  ];

  if (isLoading) return;

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={languageCtx.getStringFromId("What is it description")}
        />
        <meta
          name="og:description"
          content={languageCtx.getStringFromId("shortdescription")}
        />
      </Helmet>
      <HeaderControl />

      <div className="page align-center">
        {!albumUtil.isAlbum(album) && (
          <div className="text-center py-5">
            <div className="display-6">
              {languageCtx.getStringFromId("Album does not exist")}
            </div>
          </div>
        )}
        {albumUtil.isAlbum(album) && (
          <section className="section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-9">
                  <div className="">
                    <div className="mb-5 section-title">
                      {languageCtx.getStringFromId("Invite cards")}
                    </div>
                    <div className="section-description">
                      {languageCtx.getStringFromId(
                        "Invite cards can be helpful at bigger events..."
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-5">
                {steps.map((step, index) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                      <div className="p-4">
                        <div className="w-100" style={{ textAlign: "center" }}>
                          <div className="step-number-container">
                            <span className="step-number mbr-fonts-style">
                              {index + 1}
                            </span>
                          </div>
                        </div>

                        <div className="step-title mt-4">{step.title}</div>
                        <div className="step-description mt-2">
                          {step.content}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-blue btn-lg"
                  onClick={() => setShowOptionsModal(true)}
                >
                  {languageCtx.getStringFromId("Download invite cards")}
                </button>
              </div>
              <QRCode
                value={albumUtil.getInviteLink({ _id: params.albumId })}
                size={1024}
                ref={qrCodeRef}
                className="d-none"
              />
              <div className="mt-3 step-description">
                {languageCtx.getStringFromId(
                  "You can also create your own invite cards, "
                )}{" "}
                <a href="#" onClick={handleDownloadQrCodeClicked}>
                  {languageCtx.getStringFromId("download the QR code")}
                </a>
              </div>
            </div>
          </section>
        )}
      </div>
      <Modal show={showOptionsModal} onHide={() => setShowOptionsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {languageCtx.getStringFromId("Invite cards")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center px-3">
            <div className="row mt-4 justify-content-center">
              {actions
                .filter((a) => a.show())
                .map((d, index) => (
                  <div className="col-6 p-2" key={index}>
                    <a href={d.url} className="noUnderline">
                      <div className="text-center p-3 clickable settings-menu-item2 border rounded h-100">
                        <div>
                          {d.img && (
                            <img
                              src={d.img}
                              className="d-inline"
                              style={{
                                objectFit: "contain",
                                width: 100,
                                height: 100,
                              }}
                            />
                          )}
                          {d.icon && (
                            <FontAwesomeIcon icon={d.icon} size="2x" />
                          )}
                        </div>
                        <div className="mt-1 fw-bold">{d.title}</div>
                        <div className="mt-1" style={{ fontSize: 12 }}>
                          {d.subtitle}
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <FooterControl />
    </div>
  );
};
