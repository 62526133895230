import { useEffect, useState } from "react";

import albumUtil from "../../../../../../../../utils/album.util";

import { useLanguage } from "../../../../../../../language.context";

import { useApp } from "../../../../../app.context";

import ConfirmControl from "./confirm.control";
import { useUser } from "../../../../user.context";

export default ({ ctx, userId }) => {
  const appCtx = useApp();
  const userCtx = useUser();
  const languageCtx = useLanguage();

  const [user, setUser] = useState();

  const refresh = async () => {
    const user = await appCtx.apiServerClient.album.getUser(
      userCtx.album.get()._id,
      userId
    );

    setUser(user);
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleUnblockUserPressed = async () => {
    ctx.pushItem(languageCtx.getIdFromId("Unblock"), (ctx) => (
      <ConfirmControl ctx={ctx} block={false} user={user} />
    ));
  };

  const handleBlockUserAndDeleteAllPhotosPressed = async () => {
    ctx.pushItem(languageCtx.getIdFromId("Block"), (ctx) => (
      <ConfirmControl ctx={ctx} block={true} user={user} />
    ));
  };

  if (!user) return null;

  return (
    <div className="p-4">
      {user && (
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 20, fontWeight: "bold" }}>{user.name}</div>
          {user.email && <div style={{ fontSize: 14 }}>{user.email}</div>}

          <div style={{ fontSize: 14 }}>
            {albumUtil.isUserOwner(userCtx.album.get(), user)
              ? languageCtx.getStringFromId("Owner")
              : languageCtx.getStringFromId("Invite")}
          </div>

          {!albumUtil.isUserOwner(userCtx.album.get(), user) && (
            <div className="mt-3">
              {user.blocked && (
                <button
                  className="btn btn-blue"
                  onClick={handleUnblockUserPressed}
                >
                  {languageCtx.getStringFromId("unblockUser")}
                </button>
              )}

              {!user.blocked && (
                <button
                  className="btn btn-blue"
                  onClick={handleBlockUserAndDeleteAllPhotosPressed}
                >
                  {languageCtx.getStringFromId("blockUserAndDeleteAllPhotos")}
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
