import albumUtil from "../../../../../../utils/album.util";

import { useLanguage } from "../../../../../language.context";

import { useUser } from "../../user.context";

import InviteControl from "../menu.model/invite.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const userCtx = useUser();

  const handleInvitePeopleClicked = async () => {
    ctx.pushItem(languageCtx.getIdFromId("Invite"), (ctx) => (
      <InviteControl ctx={ctx} />
    ));
  };

  const handleCloseClicked = async () => {
    await userCtx.album.markWelcomeMessageShown();
    ctx.setShow(false);
  };

  return (
    <div className="text-center p-3">
      <div className="display-6">
        {languageCtx.getStringFromId("yourAlbumIsReady")}
      </div>
      <div className="display-6 fw-bold mt-3">{userCtx.album.get().name}</div>

      <div className="mt-3 cover">
        {userCtx.album.get().cover && (
          <img
            src={userCtx.album.get().cover.uri}
            style={{
              width: "100%",
              height: "100%",
              objectFit:
                userCtx.album.get().cover?.source === "db"
                  ? "contain"
                  : "cover",
            }}
          />
        )}
      </div>

      {!albumUtil.isMediaDeleted(userCtx.album.get()) && (
        <div className="mt-4">
          {languageCtx.getStringFromId(
            "You can now invite people or do it later"
          )}
        </div>
      )}

      <div className="mt-4">
        <button className="btn btn-gray" onClick={handleCloseClicked}>
          {languageCtx.getStringFromId("close")}
        </button>

        {!albumUtil.isMediaDeleted(userCtx.album.get()) && (
          <button
            className="btn btn-blue ms-1"
            onClick={handleInvitePeopleClicked}
          >
            {languageCtx.getStringFromId("Invite people")}
          </button>
        )}
      </div>
    </div>
  );
};
