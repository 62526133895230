import QRCode from "react-qr-code";

import albumUtil from "../../../../../../utils/album.util";
import stringUtil from "../../../../../../utils/string.util";

import { useMessage } from "../../../../../message.context";
import { useLanguage } from "../../../../../language.context";

import { useApp } from "../../../app.context";
import { useUser } from "../../user.context";

export default ({ ctx }) => {
  const messageCtx = useMessage();
  const languageCtx = useLanguage();
  const appCtx = useApp();
  const userCtx = useUser();

  const handleCopyLinkClicked = () => {
    navigator.clipboard.writeText(albumUtil.getInviteLink(userCtx.album.get()));

    messageCtx.showSuccess("Link copied to clipboard");
  };

  const handleShareCodeClicked = async () => {
    const message = stringUtil.formatString(
      languageCtx.getStringFromId("clickLinkToJoin"),
      userCtx.album.get().name,
      albumUtil.getInviteLink(userCtx.album.get())
    );
    try {
      await navigator.share({ text: message });
    } catch {}
  };

  return (
    <div className="app">
      <div className="text-center p-4">
        <div className="fs-2 mt-3">
          {languageCtx.getStringFromId("How to invite people")}
        </div>
        {albumUtil.isUserOwner(userCtx.album.get(), appCtx.user.get()) && (
          <>
            <div className="mt-3">
              {languageCtx.getStringFromId(
                "Scan code below or click one of the buttons"
              )}
            </div>

            <div className="mt-4">
              <QRCode
                value={albumUtil.getInviteLink(userCtx.album.get())}
                className="w-100 inviteqrcode"
              />
            </div>

            <div className="mt-4">
              <div className="justify-content-center">
                <button
                  type="button"
                  className="btn btn-blue mx-1"
                  onClick={handleCopyLinkClicked}
                >
                  {languageCtx.getStringFromId("Copy link")}
                </button>
                {navigator.share && (
                  <button
                    type="button"
                    className="btn btn-blue mx-1"
                    onClick={handleShareCodeClicked}
                  >
                    {languageCtx.getStringFromId("Share link")}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {!albumUtil.isUserOwner(userCtx.album.get(), appCtx.user.get()) && (
          <div className="mt-3">
            {languageCtx.getStringFromId(
              "Only the album owner can invite people"
            )}
          </div>
        )}
      </div>
    </div>
  );
};
