import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { DateTime } from "luxon";
import { Outlet, useNavigate } from "react-router-dom";

import mediaUtil from "../../../../utils/media.util";
import localStorageUtil from "../../../../utils/localStorage.util";
import urlUtil from "../../../../utils/url.util";

import { useException } from "../../../exception.context";

import { useApp } from "../app.context";
import { useMessage } from "../../../message.context";
import { useLanguage } from "../../../language.context";

const albumContext = createContext();

export const useUser = () => {
  return useContext(albumContext);
};

export const MediasOrderBys = {
  name: "name",
  date: "date",
};

export const UserContextProvider = () => {
  const navigate = useNavigate();
  const appCtx = useApp();

  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const languageCtx = useLanguage();

  const [albumWelcomeMessageShown, setAlbumWelcomeMessageShown] =
    useState(true);
  const [isLoadingAlbum, setIsLoadingAlbum] = useState(true);
  const [mediasOrderBy, setMediasOrderBy] = useState(MediasOrderBys.name);
  const [albums, setAlbums] = useState([]);
  const [album, setAlbum] = useState();
  const [medias, setMedias] = useState([]);
  const [mediasTimestamp, setMediasTimestamp] = useState(
    DateTime.now().toISO()
  );
  const [isLoadingMedias, setIsLoadingMedias] = useState(true);
  const getMediaChangesIdRef = useRef(0);

  const getMediaChanges = async () => {
    try {
      const info = await loadMediaChangesFromAlbumId(
        album?._id,
        mediasTimestamp
      );
      let medias2 = [...medias];

      if (info.medias.length > 0 || info.deletedMedias.length > 0) {
        for (var media of info.medias) {
          medias2 = medias2.filter((m) => m._id !== media._id);
          medias2.push(media);
        }

        for (var deletedMedia of info.deletedMedias) {
          medias2 = medias2.filter((m) => m._id !== deletedMedia._id);
        }

        setMedias(medias2);
      }

      setMediasTimestamp(info.timestamp);
    } catch (ex) {
      console.error(ex);
      // not sure why we get here, but we do
    }

    setupNextUpdate();
  };

  const setupNextUpdate = () => {
    clearTimeout(getMediaChangesIdRef.current);
    if (medias.some((m) => !mediaUtil.hasBeenProcessed(m))) {
      // update every 30 seconds
      getMediaChangesIdRef.current = setTimeout(getMediaChanges, 1000 * 30);
    } else {
      if (medias.length < 10) {
        // update every 2 minutes
        getMediaChangesIdRef.current = setTimeout(
          getMediaChanges,
          1000 * 60 * 2
        );
      } else {
        // update every 5 minutes
        getMediaChangesIdRef.current = setTimeout(
          getMediaChanges,
          1000 * 60 * 5
        );
      }
    }
  };

  useEffect(() => {
    if (!appCtx.user.get()) {
      navigate("/app/signin/enteremail");
    }
  }, []);

  useEffect(() => {
    const refresh = async () => {
      if (!appCtx.user.get()) return;

      try {
        setIsLoadingAlbum(true);
        let albums = [],
          album;

        try {
          if (urlUtil.isAppAlbum()) {
            const segments = window.location.pathname.split("/");
            const albumId = segments[segments.length - 1];

            ({ albums, album } = await loadAlbumsAlbumFromUser(
              appCtx.user.get(),
              [albumId, loadAlbumId(appCtx.user.get())]
            ));
            if (album?._id !== albumId) {
              messageCtx.showError(
                languageCtx.getStringFromId("Album does not exist")
              );

              if (album) {
                navigate(`/app/albums/${album._id}`);
              } else {
                navigate(`/app/albums`);
              }
            }
          } else {
            ({ albums, album } = await loadAlbumsAlbumFromUser(
              appCtx.user.get(),
              [loadAlbumId(appCtx.user.get())]
            ));
          }
        } catch (ex) {
          //console.error(ex);
        }

        setAlbums(albums);
        setAlbumAndSaveToLocalStorage(appCtx.user.get(), album);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoadingAlbum(false);
      }
    };

    refresh();
  }, [appCtx.user.get()?.email]);

  useEffect(() => {
    if (!album) return;

    setupNextUpdate();

    return () => {
      clearTimeout(getMediaChangesIdRef.current);
    };
  }, [medias, album?._id]);

  useEffect(() => {
    if (!appCtx.user.get()) return;

    const mediasOrderBy = loadMediasOrderBy(appCtx.user.get());

    setAndSaveMediasOrderBy(mediasOrderBy);
  }, [appCtx.user.get()?._id]);

  useEffect(() => {
    if (!album) return;

    const albumWelcomeMessageShown = loadAlbumWelcomeMessageShown(
      appCtx.user.get(),
      album
    );
    saveAndSetAlbumWelcomeMessageShown(albumWelcomeMessageShown);
  }, [album?._id]);

  useEffect(() => {
    const refreshMedias = async (album2) => {
      const info = await loadMediasFromAlbumId(album2?._id);

      setMedias(info.medias);
      setMediasTimestamp(info.timestamp);
    };

    refreshMedias(album);
  }, [album?._id, appCtx.user.get()?._id]);

  const loadAlbumWelcomeMessageShown = (user, album) => {
    try {
      const shown =
        localStorageUtil.get(
          `${user._id}.albums.${album._id}.welcomeMessageShown`
        ) === "1" || false;

      return shown;
    } catch {
      return false;
    }
  };

  const saveAlbumWelcomeMessageShown = (shown) => {
    try {
      localStorageUtil.set(
        `${appCtx.user.get()._id}.albums.${album._id}.welcomeMessageShown`,
        shown ? "1" : "0"
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const saveAndSetAlbumWelcomeMessageShown = (shown) => {
    saveAlbumWelcomeMessageShown(shown);
    setAlbumWelcomeMessageShown(shown);
  };

  const markAlbumWelcomeMessageShown = async () => {
    saveAndSetAlbumWelcomeMessageShown(true);
  };

  const loadMediasOrderBy = () => {
    let orderBy;
    try {
      orderBy =
        localStorageUtil.get(`${appCtx.user.get()._id}.medias.orderBy`) ||
        MediasOrderBys.name;
    } catch (ex) {
      orderBy = MediasOrderBys.name;
    }
    return orderBy;
  };

  const saveMediasOrderBy = (orderBy) => {
    try {
      localStorageUtil.set(`${appCtx.user.get()._id}.medias.orderBy`, orderBy);
    } catch (ex) {
      console.error(ex);
    }
  };

  const setAndSaveMediasOrderBy = (orderBy) => {
    saveMediasOrderBy(orderBy);
    setMediasOrderBy(orderBy);
  };

  const joinAlbum = async (albumId) => {
    const album = await appCtx.apiServerClient.album.join(albumId);

    const albums2 = [...albums];

    if (!albums2.find((a) => a._id === album._id)) {
      albums2.push(album);
    }

    setAlbums(albums2);
    setAlbumAndSaveToLocalStorage(appCtx.user.get(), album);
  };

  const loadAlbumsAlbumFromUser = async (user, albumIds) => {
    if (!user) {
      return { albums: [] };
    }

    const albums = await appCtx.apiServerClient.album.getAll();

    let album;
    for (var albumId of albumIds.filter((i) => i)) {
      album = albums.find((a) => a._id === albumId);

      if (album) break;
    }

    if (!album) {
      if (albums.length) {
        // get non demo open album if exist
        album = albums.find(
          (a) => a.status.started && !a.status.ended && !a.isDemo
        );

        if (!album) {
          // get any open album if exist
          album = albums.find((a) => a.status.started && !a.status.ended);

          if (!album) {
            // get first album
            album = albums[0];
          }
        }
      }
    }

    albums.sort((a, b) => a.name.localeCompare(b.name));

    return { albums, album };
  };

  const loadMediasFromAlbumId = async (albumId) => {
    if (!albumId) {
      return { medias: [], timestamp: DateTime.now().toISO() };
    }

    setMedias([]);
    setIsLoadingMedias(true);

    const info = await appCtx.apiServerClient.media.getByAlbumId(albumId);

    setIsLoadingMedias(false);

    return info;
  };

  const loadMediaChangesFromAlbumId = async (albumId, timestamp) => {
    if (!albumId) {
      return {
        medias: [],
        deletedMedias: [],
        timestamp: DateTime.now().toISO(),
      };
    }

    const info = await appCtx.apiServerClient.media.getChangesByAlbumId(
      albumId,
      timestamp
    );

    return info;
  };

  // medias

  const deleteMedia = async (mediaId) => {
    const album = await appCtx.apiServerClient.media.delete(mediaId);

    updateAlbums(album);

    const medias2 = medias.filter((m) => m._id !== mediaId);
    setMedias(medias2);
  };

  // album

  const setAlbumByPaymentClientSecret = async (clientSecret) => {
    const album = await appCtx.apiServerClient.album.getByClientSecret(
      clientSecret
    );

    const { albums, album: album2 } = await loadAlbumsAlbumFromUser(
      appCtx.user.get(),
      [album._id]
    );
    setAlbums(albums);
    setAlbumAndSaveToLocalStorage(appCtx.user.get(), album2);
    return album2;
  };

  const setAlbumAndSaveToLocalStorage = (user, album) => {
    if (!user) return null;

    if (album) {
      localStorageUtil.set(`${user._id}.albumId`, album._id);
    } else {
      localStorageUtil.remove(`${user._id}.albumId`);
    }

    setAlbum(album);
  };

  const loadAlbumId = (user) => {
    if (!user) return null;

    const albumId = localStorageUtil.get(`${user._id}.albumId`);

    return albumId;
  };

  const updateAlbums = (album) => {
    const albums2 = albums.filter((a) => a._id !== album?._id);
    albums2.push(album);
    setAlbums(albums2);
    setAlbum(album);
  };

  const createAlbum = async (name, cover, startDate, timeZone, packageId) => {
    const album = await appCtx.apiServerClient.album.create(
      name,
      cover,
      startDate,
      timeZone,
      packageId
    );

    updateAlbums(album);

    return album;
  };

  const createAlbumFromGiftcard = async (
    name,
    cover,
    startDate,
    timeZone,
    code
  ) => {
    const album = await appCtx.apiServerClient.album.createFromGiftcard(
      name,
      cover,
      startDate,
      timeZone,
      code
    );

    updateAlbums(album);

    return album;
  };

  const updateAlbumCover = async (cover) => {
    const album2 = await appCtx.apiServerClient.album.updateCover(
      album._id,
      cover
    );
    updateAlbums(album2);
  };

  const updateAlbumName = async (name) => {
    const album2 = await appCtx.apiServerClient.album.updateName(
      album._id,
      name
    );
    updateAlbums(album2);
    return album2;
  };

  const updateAlbumStartDate = async (startDate, timeZone) => {
    const album2 = await appCtx.apiServerClient.album.updateStartDate(
      album._id,
      startDate,
      timeZone
    );
    updateAlbums(album2);
  };

  if (isLoadingAlbum) return;

  const initialValue = {
    mediasOrderBy,
    setMediasOrderBy: setAndSaveMediasOrderBy,

    albums: {
      get: () => albums,
    },

    album: {
      get: () => album,
      set: (album) => setAlbumAndSaveToLocalStorage(appCtx.user.get(), album),
      markWelcomeMessageShown: markAlbumWelcomeMessageShown,
      welcomeMessageShown: albumWelcomeMessageShown,
      updateName: updateAlbumName,
      updateStartDate: updateAlbumStartDate,
      updateCover: updateAlbumCover,
      create: createAlbum,
      createFromGiftcard: createAlbumFromGiftcard,
      setByPaymentClientSecret: setAlbumByPaymentClientSecret,
      join: joinAlbum,
    },

    medias: {
      get: () => medias,
      isLoading: isLoadingMedias,
      delete: deleteMedia,
      refreshFromChanges: getMediaChanges,
    },
  };

  return (
    <albumContext.Provider value={initialValue}>
      <Outlet />
    </albumContext.Provider>
  );
};
