import { Navigate, Route, Routes } from "react-router-dom";

import { useApp } from "./app.context";

import InviteCardsPage from "./invitecards.page";
import JoinAlbumPage from "./joinalbum.page";
import JoinDemoPage from "./joindemo.page";
import PaymentPage from "./payment.page";
import PresentAlbumPage from "./presentalbum.page";

import EnterNamePage from "./nouser/entername.control";
import EnterEmailPage from "./nouser/enteremail.control";
import EnterVerificationCodePage from "./nouser/enterverificationcode.control";

import NoAlbumsPage from "./user/noalbums/noalbums.page";
import AlbumPage from "./user/albums/album.page";
import { NouserContextProvider } from "./nouser/nouser.context";
import { UserContextProvider } from "./user/user.context";

const Redirect = () => {
  const appCtx = useApp();

  if (appCtx.user.get()) {
    return <Navigate to="/app/albums" />;
  } else {
    return <Navigate to="/app/signin/enteremail" />;
  }
};

export default () => {
  return (
    <Routes>
      {/* used to present album */}
      <Route path="presentalbum/:albumId" element={<PresentAlbumPage />} />
      <Route path="joinalbum/:albumId" element={<JoinAlbumPage />} />
      <Route path="joindemo" element={<JoinDemoPage />} />
      {/* used via link from client email */}
      <Route path="invitecards/:albumId" element={<InviteCardsPage />} />
      {/* used on create album/update package */}
      <Route path="payment" element={<PaymentPage />} />

      <Route path="signin" element={<NouserContextProvider />}>
        <Route path="enteremail" element={<EnterEmailPage />} />
        <Route path="entername" element={<EnterNamePage />} />
        <Route
          path="enterverificationcode"
          element={<EnterVerificationCodePage />}
        />
      </Route>
      <Route path="albums" element={<UserContextProvider />}>
        <Route index element={<NoAlbumsPage />} />
        <Route path=":albumId">
          <Route index element={<AlbumPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};
