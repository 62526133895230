import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import { useLanguage } from "../../../../../language.context";

import Modal from "../../../../../controls/modal";

import { useApp } from "../../../app.context";

import MainControl from "./control";

export default ({ show, setShow }) => {
  const languageCtx = useLanguage();
  const appCtx = useApp();

  const bodyRef = useRef();
  const [isWorking, setIsWorking] = useState(false);

  const [name, setName] = useState("");
  const [store, setStore] = useState();
  const [packages, setPackages] = useState([]);
  const [_package, setPackage] = useState();
  const [startDate, setStartDate] = useState(DateTime.now().toISODate());
  const [timeZone] = useState(DateTime.now().zoneName);
  const [cover, setCover] = useState();
  const [giftcardCode, setGiftcardCode] = useState("");

  const [startDate2, setStartDate2] = useState();
  const [cover2, setCover2] = useState();

  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  // stack
  const [items, setItems] = useState([
    {
      titleLanguageStringId: languageCtx.getIdFromId("Menu"),
      component: (ctx) => <MainControl ctx={ctx} />,
    },
  ]);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTo({ top: 0 });
    }
  }, [items]);

  const handleBackClicked = () => {
    if (isWorking) return;

    popItem();
  };

  const pushItem = (titleLanguageStringId, component, onBackClicked) => {
    setItems([...items, { titleLanguageStringId, component, onBackClicked }]);
  };

  const popItem = (count = 1) => {
    const items2 = [...items];
    while (count-- > 0) {
      // cannot remove rootItem
      if (items2.length > 1) {
        if (items2[items2.length - 1].onBackClicked) {
          items2[items2.length - 1].onBackClicked();
        }
        items2.pop();
      }
    }

    setItems(items2);
  };

  // const reset = () => {
  //   while (items.length > 1) {
  //     if (items[items.length - 1].onBackClicked) {
  //       items[items.length - 1].onBackClicked(ctx);
  //     }
  //     items.pop();
  //   }

  //   setItems([...items]);
  // };

  const setShowProxy = (show) => {
    if (!show) {
      setTimeout(() => popItem(100), 500);
    }

    // TODO reset
    setShow(show);
  };

  if (items.length <= 0) return;

  const currentItem = items[items.length - 1];

  const ctx = {
    // stack
    hasParent: items.length > 1,
    currentItem,
    pushItem,
    popItem,
    items,

    // data
    changePackage: {
      packages,
      setPackages,

      package: _package,
      setPackage,

      reset: () => {
        setPackages([]);
        setPackage();
      },
    },

    createAlbum: {
      // data
      giftcardCode,
      setGiftcardCode,

      store,
      setStore,

      name,
      setName,

      packages,
      setPackages,

      package: _package,
      setPackage,

      timeZone,

      startDate,
      setStartDate,

      cover,
      setCover,

      reset: () => {
        setName("");
        setStore();
        setPackages([]);
        setPackage();
        setStartDate(DateTime.now().toISODate());
        setCover();
        setGiftcardCode("");
      },
    },

    registerUser: {
      onSuccess: "pop3",

      email,
      setEmail,

      verificationCode,
      setVerificationCode,

      reset: () => {
        setEmail("");
        setVerificationCode("");
      },
    },

    updateCover: {
      cover: cover2,
      setCover: setCover2,

      reset: () => {
        setCover2();
      },
    },

    updateStartDate: {
      startDate: startDate2,
      setStartDate: setStartDate2,

      reset: () => {
        setStartDate2();
      },
    },

    isWorking,
    setIsWorking,

    // modal
    show,
    setShow: setShowProxy,
  };

  return (
    <>
      <Modal
        show={show}
        setShow={setShowProxy}
        title={
          <>
            {items.length > 1 ? (
              <span onClick={handleBackClicked} className="clickable">
                <FontAwesomeIcon
                  style={{
                    margin: -10,
                    margin: -10,
                    padding: 10,
                    padding: 10,
                  }}
                  icon={faCaretLeft}
                />
                <span className="ms-2">
                  {languageCtx.getStringFromId(
                    currentItem.titleLanguageStringId
                  )}
                </span>
              </span>
            ) : (
              <span>
                {languageCtx.getStringFromId(currentItem.titleLanguageStringId)}
              </span>
            )}
          </>
        }
      >
        {currentItem.component(ctx)}
      </Modal>
    </>
  );
};
