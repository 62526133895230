import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import urlUtil from "../utils/url.util";

import ErrorBoundaryFeature from "./errorboundary.feature";
import AppRequirementsCheckFeature from "./apprequirementscheck.feature";
import { LanguageContextProvider } from "./language.context";
import { MessageContextProvider } from "./message.context";
import { ExceptionContextProvider } from "./exception.context";
import Nav from "./pages/nav";
import "./App.css";

const redirect = () => {
  if (urlUtil.isOldAppAlbumJoin()) {
    const albumId = window.location.pathname
      .replace(/\/app\/albums\//i, "")
      .replace(/\/join/i, "");
    window.location = `/app/joinalbum/${albumId}`;
    return;
  }

  if (urlUtil.isOldPresentAlbum()) {
    const albumId = window.location.pathname
      .replace(/\/app\/albums\//i, "")
      .replace(/\/presentation/i, "");
    window.location = `/app/presentalbum/${albumId}`;
    return;
  }

  if (urlUtil.isOldInviteCards()) {
    const segments = window.location.pathname.split("/");
    const albumId = segments[segments.length - 1];
    window.location = `/app/invitecards/${albumId}`;
    return;
  }
};

function App() {
  redirect();

  return (
    <ErrorBoundaryFeature>
      <HelmetProvider>
        <BrowserRouter>
          <LanguageContextProvider>
            <AppRequirementsCheckFeature>
              <MessageContextProvider>
                <ExceptionContextProvider>
                  <Nav />
                </ExceptionContextProvider>
              </MessageContextProvider>
            </AppRequirementsCheckFeature>
          </LanguageContextProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundaryFeature>
  );
}

export default App;
