//import { useEffect, useState } from "react";

import { useEffect, useState } from "react";
import { useLanguage } from "../../../../../../../language.context";
import { useApp } from "../../../../../app.context";
import { useException } from "../../../../../../../exception.context";

export default ({ ctx, version }) => {
  const appCtx = useApp();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const [termsOfService, setTermsOfService] = useState();

  useEffect(() => {
    const refresh = async () => {
      try {
        const termsOfService =
          await appCtx.apiServerClient.termsOfService.getByVersion(
            version || appCtx.user.get().termsOfService.version,
            languageCtx.language.code
          );

        setTermsOfService(termsOfService);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, []);

  if (!termsOfService) return null;

  return (
    <div
      className="p-4 privacypolicy_termsofservice"
      dangerouslySetInnerHTML={{ __html: termsOfService.html }}
    ></div>
  );
};
