import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WelcomeModal from "./welcome2.modal/modal";
import { useUser } from "../user.context";

const NoAlbumPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser();

  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  useEffect(() => {
    if (userCtx.album.get()) {
      navigate(`/app/albums/${userCtx.album.get()._id}`);
    }
  }, []);

  return <WelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />;
};

export default NoAlbumPage;
