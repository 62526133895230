import { AppContextProvider } from "./app.context";
import Nav from "./nav";

export default () => {
  return (
    <AppContextProvider>
      <Nav />
    </AppContextProvider>
  );
};
