import { useEffect, useState } from "react";

import { useLanguage } from "../../../../../../../language.context";
import { useException } from "../../../../../../../exception.context";

import { useApp } from "../../../../../app.context";

export default () => {
  const appCtx = useApp();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const [languages, setLanguages] = useState([]);
  const [languageCode, setLanguageCode] = useState();

  const refresh = async () => {
    try {
      const languages = await appCtx.apiServerClient.language.get();

      setLanguages(languages);
      setLanguageCode(languageCtx.language.code);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleSavePressed = async () => {
    try {
      await languageCtx.setLanguageAsync(languageCode);
      //await languageCtx.setLanguageAsync(languageCode);
      //window.location.reload();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (!languageCode) return null;

  return (
    <div className="text-center p-4">
      <div className="fs-2">{languageCtx.getStringFromId("Language")}</div>
      <div className="mt-4">
        <select
          className="form-select"
          value={languageCode}
          onChange={(e) => setLanguageCode(e.target.value)}
        >
          {languages.map((l) => (
            <option key={l.code} value={l.code}>
              {l.description}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-3">
        <button onClick={handleSavePressed} className="btn btn-blue mt-3">
          {languageCtx.getStringFromId("Save")}
        </button>
      </div>
    </div>
  );
};
