import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import { useException } from "../../../../exception.context";
import { useLanguage } from "../../../../language.context";
import TextInput from "../../../../controls/text.input";
import TableControl from "../../../../controls/table.control";
import PayControl from "../../../../controls/pay.control";

import { useAffiliateApp } from "../../app.context";

export default () => {
  const parms = useParams();

  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appCtx = useAffiliateApp();

  const [invoice, setInvoice] = useState();
  const [step, setStep] = useState(1);
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        const invoice = await appCtx.apiServerClient.affiliate.getInvoiceById(
          parms.invoiceId
        );

        invoice.charges.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

        setInvoice(invoice);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  const handleCreateClientSecret = async () => {
    const clientSecret =
      await appCtx.apiServerClient.affiliate.createPayInvoicePaymentIntent(
        invoice._id,
        languageCtx.language.code
      );
    return clientSecret;
  };

  const handleNextClicked = () => {
    setStep(2);
  };

  if (!invoice) return null;

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>{languageCtx.getStringFromId("Invoice")}</h3>
        </div>
        {step === 1 && (
          <>
            <div className="mt-2">
              <label className="form-label fw-bold">
                {languageCtx.getStringFromId("date")}
              </label>
              <TextInput
                readonly={true}
                value={DateTime.fromISO(invoice.createdAt).toISODate()}
              />
            </div>
            <div className="mt-2">
              <label className="form-label fw-bold">
                {languageCtx.getStringFromId("Due")}
              </label>
              <TextInput
                readonly={true}
                value={DateTime.fromISO(invoice.dueAt).toISODate()}
              />
            </div>
            {invoice.paidAt && (
              <div className="mt-2">
                <label className="form-label fw-bold">
                  {languageCtx.getStringFromId("Paid")}
                </label>
                <TextInput
                  readonly={true}
                  value={DateTime.fromISO(invoice.paidAt).toISODate()}
                />
              </div>
            )}
            <div className="mt-2">
              <label className="form-label fw-bold">
                {languageCtx.getStringFromId("Charges")}
              </label>
              <TableControl
                rows={invoice.charges}
                columns={[
                  {
                    header: languageCtx.getStringFromId("date"),
                    valueExtractor: (c) =>
                      DateTime.fromISO(c.createdAt).toISODate(),
                  },
                  {
                    header: languageCtx.getStringFromId("Giftcard code"),
                    valueExtractor: (t) => t.giftcard?.code,
                  },
                  {
                    header: languageCtx.getStringFromId("Reference"),
                    valueExtractor: (t) => t.giftcard?.reference,
                  },
                  {
                    header: languageCtx.getStringFromId("Amount"),
                    className: "text-end",
                    valueExtractor: (c) =>
                      `${(c.amountMicros / 1000000).toFixed(2)} ${
                        appCtx.affiliate.get().currencyCode
                      }`,
                  },
                ]}
                keyExtractor={(c) => c._id}
                footerColumns={[
                  {
                    colspan: 3,
                    valueExtractor: (f) => "",
                  },
                  {
                    className: "text-end",
                    valueExtractor: (r) =>
                      `${r.balance} ${appCtx.affiliate.get().currencyCode}`,
                  },
                ]}
                footerKeyExtractor={(r) => r.balance}
                footerRows={[
                  { balance: (invoice.amountMicros / 1000000).toFixed(2) },
                ]}
              />
            </div>

            {!invoice.paidAt && (
              <div className="mt-2">
                <button className="btn btn-blue" onClick={handleNextClicked}>
                  {languageCtx.getStringFromId("pay")}
                </button>
              </div>
            )}
          </>
        )}
        {step === 2 && (
          <PayControl
            onCreateClientSecret={handleCreateClientSecret}
            isWorking={isWorking}
            setIsWorking={setIsWorking}
            currency={appCtx.affiliate.get().currencyCode}
            price={invoice.amountMicros / 1000000}
            paymentCompletionUrl={
              window.location.origin + "/affiliate/giftcards/payment"
            }
          />
        )}
      </div>
    </div>
  );
};
