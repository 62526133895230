import { useState } from "react";

import { useLanguage } from "../../../../../../language.context";
import { useException } from "../../../../../../exception.context";
import { useMessage } from "../../../../../../message.context";

import { useUser } from "../../../user.context";

export default ({ ctx }) => {
  const userCtx = useUser();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [isWorking, setIsWorking] = useState(false);
  const [name, setName] = useState(userCtx.album.get().name);

  const handleSavePressed = async () => {
    try {
      setIsWorking(true);
      await userCtx.album.updateName(name);

      messageCtx.showSuccess(languageCtx.getStringFromId("Name changed"));
      ctx.popItem();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!name) return false;
    if (name.length < 3) return false;

    return true;
  };

  return (
    <div>
      <div className="text-center p-4">
        <div className="fs-2">
          {languageCtx.getStringFromId("Enter new name")}
        </div>
        <div className="mt-3">
          <input
            className="form-control text-center form-control-lg"
            autoFocus={true}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>

        <div className="mt-4">
          <button
            className="btn btn-blue"
            onClick={handleSavePressed}
            disabled={!isFormOk()}
          >
            {languageCtx.getStringFromId("Save")}
          </button>
        </div>
      </div>
    </div>
  );
};
