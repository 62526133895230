import { Routes, Route, Navigate } from "react-router-dom";

import MainPage from "./main.page";
import App from "./app/app";
import AffiliateApp from "./affiliate/app";

const Redirect = () => {
  return <Navigate to="/" />;
};

export default () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<MainPage />} />
          <Route path="app/*" element={<App />} />
          <Route path="affiliate/*" element={<AffiliateApp />} />
        </Route>
        <Route path="*" element={<Redirect />} />
      </Routes>
    </>
  );
};
