import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import { useAffiliateApp } from "./app.context";

import WelcomePage from "./user/welcome/welcome.page";
import ProfilePage from "./user/profile/profile.page";
import GiftcardsPage from "./user/giftcards/giftcards.page";
import PaymentPage from "../app/payment.page";
import InvoicesPage from "./user/invoices/invoices.page";
import InvoicePage from "./user/invoices/invoice.page";
import ChargesPage from "./user/charges/charges.page";
import GiftcardPage from "./user/giftcards/giftcard.page";
import SigninPage from "./nouser/signin.page";
import { useEffect, useState } from "react";

const Redirect = () => {
  const appCtx = useAffiliateApp();

  if (appCtx.affiliate.get()) {
    return <Navigate to="/affiliate/welcome" replace={true} />;
  } else {
    return <Navigate to="/affiliate/signin" replace={true} />;
  }
};

const UserRestrictedArea = () => {
  const navigate = useNavigate();

  const appCtx = useAffiliateApp();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!appCtx.affiliate.get()) {
      navigate("/affiliate/signin");
    }
    setIsLoading(false);
  }, []);

  if (isLoading) return;

  return <Outlet />;
};

export default () => {
  return (
    <Routes>
      <Route path="signin" element={<SigninPage />} />
      <Route element={<UserRestrictedArea />}>
        <Route path="welcome" element={<WelcomePage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="giftcards">
          <Route index element={<GiftcardsPage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="invoices">
            <Route index element={<InvoicesPage />} />
            <Route path=":invoiceId" element={<InvoicePage />} />
          </Route>
          <Route path="charges" element={<ChargesPage />} />
          <Route path="new" element={<GiftcardPage />} />
          <Route path=":giftcardId" element={<GiftcardPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};
