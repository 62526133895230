import { useState } from "react";

import { useLanguage } from "../../../../../../../language.context";
import { useException } from "../../../../../../../exception.context";
import { useMessage } from "../../../../../../../message.context";

import { useApp } from "../../../../../app.context";
import { useUser } from "../../../../user.context";

export default ({ ctx, block, user }) => {
  const appCtx = useApp();
  const userCtx = useUser();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [isWorking, setIsWorking] = useState(false);

  const blockUser = async (blocked) => {
    try {
      setIsWorking(true);

      await appCtx.apiServerClient.album.updateUser(
        userCtx.album.get()._id,
        user._id,
        blocked
      );

      if (blocked) {
        messageCtx.showSuccess(languageCtx.getStringFromId("User blocked"));
      } else {
        messageCtx.showSuccess(languageCtx.getStringFromId("User unblocked"));
      }

      ctx.popItem(2);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const handleCancelClicked = () => {
    ctx.popItem();
  };

  return (
    <div className="p-4">
      <div style={{ textAlign: "center" }}>
        {block && (
          <>
            <div className="fs-2 lh-sm">
              {languageCtx.getStringFromId("Sure you want to block user?")}
            </div>
            <div className="mt-3">
              {languageCtx.getStringFromId(
                "This will block user access to your album. All photos and videos uploaded by user will be deleted."
              )}
            </div>
          </>
        )}
        {!block && (
          <>
            <div className="fs-2 lh-sm">
              {languageCtx.getStringFromId("Sure you want to unblock user?")}
            </div>
            <div className="mt-3">
              {languageCtx.getStringFromId(
                "This will allow the user to upload photos and videos to the album again"
              )}
            </div>
          </>
        )}

        <div className="mt-3">
          <button
            className="btn btn-gray"
            onClick={handleCancelClicked}
            disabled={isWorking}
          >
            {languageCtx.getStringFromId("no")}
          </button>
          {user.blocked && (
            <button
              className="btn btn-blue ms-1"
              onClick={() => blockUser(false)}
              disabled={isWorking}
            >
              {languageCtx.getStringFromId("yes")}
            </button>
          )}

          {!user.blocked && (
            <button
              className="btn btn-blue ms-1"
              onClick={() => blockUser(true)}
              disabled={isWorking}
            >
              {languageCtx.getStringFromId("yes")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
