import { useEffect, useState } from "react";

import { faLink, faPlus } from "@fortawesome/free-solid-svg-icons";

import stringUtil from "../../../../../../utils/string.util";
import userUtil from "../../../../../../utils/user.util";

import { useLanguage } from "../../../../../language.context";
import ButtonMenuControl from "../../../../../controls/buttonmenu.control";

import { useApp } from "../../../app.context";

import CreateAlbumEnternameControl from "../../albums/menu.model/createalbum/enterName.control";
import JoinAlbumControl from "../../albums/menu.model/joinalbum.control";
import LogoutControl from "../../albums/menu.model/app/account/logout.control";
import WanttoregisterControl from "../../albums/menu.model/wanttoregister.control";
import { Link } from "react-router-dom";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appCtx = useApp();

  const [demoAlbum, setDemoAlbum] = useState();

  useEffect(() => {
    const refresh = async () => {
      try {
        const demoAlbum = await appCtx.apiServerClient.album.getDemoAlbum();

        setDemoAlbum(demoAlbum);
      } catch (ex) {}
    };
    refresh();
  }, []);

  const items = [
    {
      titleLanguageStringId: languageCtx.getIdFromId("Create album"),
      subtitleLanguageStringId: languageCtx.getIdFromId(
        "Create your own album and invite others"
      ),
      icon: faPlus,
      onClick: () => {
        if (userUtil.isRegistered(appCtx.user.get())) {
          ctx.pushItem(
            languageCtx.getIdFromId("Create album"),
            (ctx) => <CreateAlbumEnternameControl ctx={ctx} />,
            () => {
              ctx.createAlbum.reset();
            }
          );
        } else {
          ctx.pushItem(languageCtx.getIdFromId("Want to register"), (ctx) => (
            <WanttoregisterControl ctx={ctx} />
          ));
        }
      },
      visible: () => true,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Join album"),
      subtitleLanguageStringId: languageCtx.getIdFromId(
        "Help your friends grow their album"
      ),
      icon: faLink,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Join album"), (ctx) => (
          <JoinAlbumControl ctx={ctx} />
        ));
      },
    },
  ];

  const handleLogoutClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("logout"), (ctx) => (
      <LogoutControl ctx={ctx} />
    ));
  };

  return (
    <div className="text-center p-3">
      <div className="fs-2">
        {stringUtil.formatString(
          languageCtx.getStringFromId("Hi xxx, welcome to fotisima!"),
          appCtx.user.get().name
        )}
      </div>
      <div className="mt-2">
        {languageCtx.getStringFromId("You are not member of any albums yet")}
      </div>
      <div className="mt-2">
        <ButtonMenuControl items={items} />
      </div>
      {demoAlbum && (
        <div className="text-center">
          {languageCtx.getStringFromId("You can also join our demo album")}{" "}
          <Link to="/app/joindemo">{languageCtx.getStringFromId("here")}</Link>
        </div>
      )}

      <div className="text-center mt-3">
        <button className="btn btn-blue" onClick={handleLogoutClicked}>
          {languageCtx.getStringFromId("logout")}
        </button>
      </div>
    </div>
  );
};
