import { useRef } from "react";
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import albumUtil from "../../../../../../../utils/album.util";
import stand2 from "../../../../../../../assets/stand3.png";
import folded from "../../../../../../../assets/Folded3.png";
import plain from "../../../../../../../assets/plain.png";

import { useLanguage } from "../../../../../../language.context";

import { useApp } from "../../../../app.context";
import { useUser } from "../../../user.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appCtx = useApp();
  const userCtx = useUser();

  const qrCodeRef = useRef();

  const handleDownloadQrCodeClicked = (e) => {
    e.preventDefault();

    const svg = qrCodeRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const inviteCardsUrl = appCtx.apiServerClient.album.getInviteCardsUrl(
    userCtx.album.get()._id,
    languageCtx.language.code
  );

  const actions = [
    {
      show: () => true,
      title: languageCtx.getStringFromId("Stand"),
      subtitleLanguageStringId: languageCtx.getStringFromId(
        'Cut out and place inside a 4" x 6" stand'
      ),
      url: inviteCardsUrl + "&type=stand",
      img: stand2,
    },
    {
      show: () => true,
      title: languageCtx.getStringFromId("Folded"),
      subtitleLanguageStringId: languageCtx.getStringFromId(
        "Cut out, fold and place directly on table"
      ),
      url: inviteCardsUrl + "&type=folded",
      img: folded,
    },
    {
      show: () => true,
      title: languageCtx.getStringFromId("Plain"),
      subtitleLanguageStringId: languageCtx.getStringFromId(
        "Cut out and place them on plates"
      ),
      url: inviteCardsUrl + "&type=plain",
      img: plain,
    },
  ];

  return (
    <>
      <div className="text-center p-4">
        <div className="fs-2">
          {languageCtx.getStringFromId("Invite cards")}
        </div>
        <div className="mt-3">
          {languageCtx.getStringFromId(
            "Invite cards can be helpful at bigger events..."
          )}
        </div>
        <div className="mt-3">
          {languageCtx.getStringFromId("Press to download")}
        </div>
        <div className="row mt-3 justify-content-center">
          {actions
            .filter((a) => a.show())
            .map((d, index) => (
              <div className="col-6 p-2" key={index}>
                <a href={d.url} className="noUnderline">
                  <div className="text-center p-3 clickable settings-menu-item2 border rounded h-100">
                    <div>
                      {d.img && (
                        <img
                          src={d.img}
                          className="d-inline"
                          style={{
                            objectFit: "contain",
                            width: 100,
                            height: 100,
                          }}
                        />
                      )}
                      {d.icon && <FontAwesomeIcon icon={d.icon} size="2x" />}
                    </div>
                    <div className="mt-1 fw-bold">{d.title}</div>
                    <div className="mt-1" style={{ fontSize: 12 }}>
                      {d.subtitle}
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
        <QRCode
          value={albumUtil.getInviteLink({ _id: userCtx.album.get()._id })}
          size={1024}
          // level={"H"}
          ref={qrCodeRef}
          className="d-none"
        />
        <div className="mt-3">
          {languageCtx.getStringFromId(
            "You can also create your own invite cards, "
          )}
          <br />
          <a href="#" onClick={handleDownloadQrCodeClicked}>
            {languageCtx.getStringFromId("download the QR code")}
          </a>
        </div>
      </div>
    </>
  );
};
