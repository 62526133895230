import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import colors from "../../../../../../utils/colors";
import albumUtil from "../../../../../../utils/album.util";

import { useLanguage } from "../../../../../language.context";

import { useApp } from "../../../app.context";
import { useUser } from "../../user.context";

const AlbumInfo = ({ album }) => {
  const languageCtx = useLanguage();

  return (
    <>
      <div>
        {albumUtil.isMediasAlmostFull(album)
          ? `${languageCtx.getStringFromId("Photos/videos")}: ${
              album.usedPhotoCount + album.usedVideoCount
            } / ${album.package.mediaCount}`
          : `${languageCtx.getStringFromId("Photos/videos")}: ${
              album.usedPhotoCount + album.usedVideoCount
            }`}
      </div>
      <div>
        {albumUtil.isUsersAlmostFull(album)
          ? `${languageCtx.getStringFromId("Invites")}: ${
              album.users.length
            } / ${album.package.inviteCount}`
          : `${languageCtx.getStringFromId("Invites")}: ${album.users.length}`}
      </div>
    </>
  );
};

export default ({ ctx }) => {
  const navigate = useNavigate();
  const appCtx = useApp();
  const userCtx = useUser();
  const languageCtx = useLanguage();

  const handleAlbumPressed = async (album) => {
    userCtx.album.set(album);
    navigate(`/app/album/${album._id}`);
    ctx.setShow(false);
  };

  const renderAlbum = (album2, index) => {
    const states = [
      {
        name: "Not started",
        isValid: (album) => !albumUtil.hasStarted(album),
        render: (album) => {
          const now = DateTime.now();
          const diff = DateTime.fromISO(album.startDate).diff(now, [
            "days",
            "hours",
          ]);

          return (
            <div>
              <div>
                {`${languageCtx.getStringFromId(
                  "The album will begin in"
                )}: ${albumUtil.getDiffAsString(languageCtx, diff)}`}
              </div>
              <AlbumInfo album={album} />
            </div>
          );
        },
      },
      {
        name: "Started and not ended",
        isValid: (album) =>
          albumUtil.hasStarted(album) && !albumUtil.hasEnded(album),
        render: (album) => {
          const now = DateTime.now();
          const diff = DateTime.fromISO(album.endDate).diff(now, [
            "days",
            "hours",
          ]);

          return (
            <div>
              <div>
                {`${languageCtx.getStringFromId(
                  "The album will end in"
                )}: ${albumUtil.getDiffAsString(languageCtx, diff)}`}
              </div>
              <AlbumInfo album={album} />
            </div>
          );
        },
      },
      {
        name: "Processing",
        isValid: (album) =>
          albumUtil.hasEnded(album) && !albumUtil.isArchived(album),
        render: (album) => {
          return (
            <div>
              <div>
                {languageCtx.getStringFromId("The album is being processed...")}
              </div>
              <AlbumInfo album={album} />
            </div>
          );
        },
      },
      {
        name: "Can download medias, will be removed",
        isValid: (album) =>
          albumUtil.isArchived(album) && !albumUtil.isMediaDeleted(album),
        render: (album) => {
          const now = DateTime.now();
          const diff =
            album.status.ended &&
            album.status.archived &&
            !album.status.mediasDeleted
              ? DateTime.fromISO(album.deleteMedias.toBeProcessedDate).diff(
                  now,
                  ["days", "hours"]
                )
              : undefined;

          return (
            <div>
              <div>
                {`${languageCtx.getStringFromId(
                  "The album will be removed in"
                )}: ${albumUtil.getDiffAsString(languageCtx, diff)}`}
              </div>
              <AlbumInfo album={album} />
            </div>
          );
        },
      },
    ];

    const state = states.find((s) => s.isValid(album2));

    return (
      <div
        key={album2._id}
        onClick={() => handleAlbumPressed(album2)}
        style={{ cursor: "pointer" }}
      >
        <div
          className={`d-flex flex-row align-items-center ${
            index < userCtx.albums.get().length - 1 ? "border-bottom" : ""
          }`}
          style={{
            height: 130,
            backgroundColor:
              album2 === userCtx.album.get()
                ? colors.lightlightblue
                : colors.white,
          }}
        >
          <div className="album-cover-container">
            {/* album image */}
            <img
              className="album-cover-img"
              style={{ objectFit: "contain" }}
              src={album2.cover.uri}
            />
          </div>

          {/* album info */}
          <div
            className="d-flex flex-column justify-content-between my-2 ms-2"
            style={{
              flex: 1,
              alignSelf: "normal",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.terniary,
                minWidth: 0,
              }}
            >
              {album2.name}{" "}
              <div style={{ fontSize: 10 }}>
                {album2.ownerUserId === appCtx.user.get()._id
                  ? languageCtx.getStringFromId("Owner")
                  : languageCtx.getStringFromId("Invite")}
              </div>
            </div>
            {state?.render(album2)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-1 select-album">
      {userCtx.albums.get().map(renderAlbum)}
    </div>
  );
};
