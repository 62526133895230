import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import colors from "../../../../utils/colors";

import { useLanguage } from "../../../language.context";
import { useException } from "../../../exception.context";

import { useApp } from "../app.context";

import { useNouser } from "./nouser.context";

export default () => {
  const navigate = useNavigate();

  const exceptionCtx = useException();
  const languageCtx = useLanguage();
  const appCtx = useApp();
  const nouserCtx = useNouser();

  const inputRef = useRef();
  const btnRef = useRef();

  useEffect(() => {
    const refresh = async () => {
      if (!nouserCtx.email) {
        navigate("../enteremail");
        return;
      }

      try {
        var termsOfService =
          await appCtx.apiServerClient.termsOfService.getLatest(
            languageCtx.language.code
          );

        var privacyPolicy =
          await appCtx.apiServerClient.privacyPolicy.getLatest(
            languageCtx.language.code
          );

        nouserCtx.setTermsOfServiceVersion(termsOfService.version);
        nouserCtx.setPrivacyPolicyVersion(privacyPolicy.version);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [languageCtx.language.code]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [nouserCtx.termsOfService]);

  const handleNextClicked = async () => {
    if (!isFormOk()) return;

    try {
      await appCtx.apiServerClient.user.sendSignupVerificationCode(
        nouserCtx.email.trim(),
        languageCtx.language.code
      );
      navigate("../enterverificationcode");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!nouserCtx.name) return false;
    if (nouserCtx.name.length < 3) return false;
    if (!nouserCtx.termsOfServiceVersion) return false;
    if (!nouserCtx.privacyPolicyVersion) return false;

    return true;
  };

  if (!nouserCtx.termsOfServiceVersion || !nouserCtx.privacyPolicyVersion)
    return;

  return (
    <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="container text-center">
        <div className="fs-2">
          {languageCtx.getStringFromId(
            "We see that you are a new user, what username would you like?"
          )}
        </div>

        <div className="container">
          <input
            ref={inputRef}
            className="form-control mt-4 text-center form-control-lg"
            value={nouserCtx.name}
            onChange={(e) => nouserCtx.setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                btnRef.current.click();
              }
            }}
          />
        </div>

        <div className="mt-4">
          <div className="text-center p-0 m-0">
            {languageCtx.getStringFromId("bySigningUpYouAcceptOur")}{" "}
            <a
              href={`privacypolicy/${nouserCtx.privacyPolicyVersion}`}
              target="_blank"
              referrerPolicy="no-referrer"
              style={{ color: colors.darkblue }}
            >
              {languageCtx.getStringFromId("privacyPolicy")}
            </a>{" "}
            {languageCtx.getStringFromId("and our")}{" "}
            <a
              href={`termsofservice/${nouserCtx.termsOfServiceVersion}`}
              target="_blank"
              referrerPolicy="no-referrer"
              style={{ color: colors.darkblue }}
            >
              {languageCtx.getStringFromId("termsOfService")}
            </a>{" "}
          </div>
        </div>

        <div className="mt-4">
          <button
            ref={btnRef}
            disabled={!isFormOk()}
            className="btn btn-lg btn-black"
            onClick={handleNextClicked}
          >
            {languageCtx.getStringFromId("Next")}
          </button>
        </div>
      </div>
    </div>
  );
};
