import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  faDownload,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigation } from "swiper/modules";
import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/virtual";

import mediaUtil from "../../../../../../utils/media.util";

import { useException } from "../../../../../exception.context";
import { useLanguage } from "../../../../../language.context";

import { useApp } from "../../../app.context";
import { useUser } from "../../user.context";

export default ({ medias, initialIndex, show, setShow }) => {
  const appCtx = useApp();
  const userCtx = useUser();
  const exceptionCtx = useException();
  const languageCtx = useLanguage();

  const [medias2, setMedias2] = useState([]);
  const [index, setIndex] = useState(-1);
  const downloadRef = useRef();

  useEffect(() => {
    if (show) {
      setMedias2(medias);
      setIndex(initialIndex);
    } else {
      setMedias2([]);
      setIndex(-1);
    }
  }, [show]);

  const handleDeleteClicked = async () => {
    try {
      await userCtx.medias.delete(media2._id);

      const medias = medias2.filter((m, index2) => m._id !== media2._id);

      if (medias.length === 0) {
        setShow(false);
        return;
      }

      if (index > medias.length - 1) {
        setIndex(medias.length - 1);
      }

      setMedias2(medias);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDownloadClicked = async (media) => {
    try {
      downloadRef.current.src = `${media2.server.origin}/api/v1/media/downloadFile?albumId=${media2.albumId}&mediaId=${media2._id}`;
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleLoadFinished = () => {
    exceptionCtx.handleException({ error: { id: "SERVER_MEDIA_NOT_FOUND" } });
  };

  if (index < 0) return null;

  const media2 = medias2[index];

  return (
    <Modal show={show} onHide={() => setShow(false)} fullscreen>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title
          style={{
            overflow: "hidden",
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            // iOS
            whiteSpace: "nowrap",
          }}
        >
          {`${index + 1} / ${medias2.length}`}
        </Modal.Title>
        <div
          style={{
            whiteSpace: "nowrap",
            // iOS
            boxSizing: "border-box",
          }}
        >
          {mediaUtil.canDelete(
            media2,
            userCtx.album.get(),
            appCtx.user.get()
          ) && (
            <span className="px-2 clickable" onClick={handleDeleteClicked}>
              <FontAwesomeIcon icon={faTrash} size="xl" />
            </span>
          )}
          <span
            className="px-2 clickable"
            onClick={() => handleDownloadClicked(media2)}
          >
            <FontAwesomeIcon icon={faDownload} size="xl" />
          </span>
          <span onClick={() => setShow(false)} className="px-2 clickable">
            <FontAwesomeIcon icon={faXmark} size="xl" />
          </span>
        </div>
      </Modal.Header>
      <Modal.Body style={{ display: "flex" }}>
        <Swiper
          //ref={swiperRef}
          modules={[Navigation, Virtual]}
          style={{ width: "100%" }}
          // spaceBetween={50}
          slidesPerView={1}
          navigation
          virtual={{ addSlidesBefore: 2, addSlidesAfter: 2 }}
          onActiveIndexChange={(swiper) => setIndex(swiper.activeIndex)}
          initialSlide={initialIndex}
        >
          {medias2.map((m, index) => (
            <SwiperSlide key={m._id} virtualIndex={m._id}>
              {m.asset.mediaType === "photo" && (
                <img
                  src={m.reducedFile.uri}
                  alt={m.asset.filename}
                  className="h-100 w-100"
                  style={{ objectFit: "contain" }}
                />
              )}
              {m.asset.mediaType === "video" && (
                <video
                  preload="none"
                  src={m.reducedFile.uri}
                  poster={m.reducedPosterFile.uri}
                  style={{ objectFit: "contain" }}
                  muted={false}
                  className="h-100 w-100"
                  controls
                  playsInline
                  x-webkit-airplay="deny"
                  title="Video"
                  loop={false}
                  load={false}
                >
                  {languageCtx.getStringFromId(
                    "Your browser does not support video."
                  )}
                </video>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <iframe
          ref={downloadRef}
          style={{ display: "none" }}
          onLoad={handleLoadFinished}
        />
      </Modal.Body>
    </Modal>
  );
};
