import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import colors from "../../../utils/colors";
import validateUtil from "../../../utils/validate.util";
import albumUtil from "../../../utils/album.util";
import userUtil from "../../../utils/user.util";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";
import { useMessage } from "../../message.context";
import Modal from "../../controls/modal";

import TermsOfServiceControl from "./user/albums/menu.model/app/account/termsofservice.control";
import PrivacyPolicyControl from "./user/albums/menu.model/app/account/privacy.control";

import { useApp } from "./app.context";

const JoinControl = ({ ctx }) => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const appCtx = useApp();
  const messageCtx = useMessage();
  const exceptionCtx = useException();

  const [name, setName] = useState("");
  const [termsOfServiceVersion, setTermsOfServiceVersion] = useState();
  const [privacyPolicyVersion, setPrivacyPolicyVersion] = useState();

  useEffect(() => {
    const refresh = async () => {
      var termsOfService =
        await appCtx.apiServerClient.termsOfService.getLatest(
          languageCtx.language.code
        );

      var privacyPolicy = await appCtx.apiServerClient.privacyPolicy.getLatest(
        languageCtx.language.code
      );

      setTermsOfServiceVersion(termsOfService.version);
      setPrivacyPolicyVersion(privacyPolicy.version);
    };

    refresh();
  }, []);

  const handleSignupAsGuestAndJoinClicked = async () => {
    try {
      await appCtx.user.signupAsGuest(
        name,
        privacyPolicyVersion,
        termsOfServiceVersion
      );
      await appCtx.apiServerClient.album.join(ctx.album._id);

      messageCtx.showSuccess(languageCtx.getStringFromId("youJoinedAnAlbum"));
      ctx.setShow(false);
      navigate(`/app/albums/${ctx.album._id}`);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleJoinClicked = async () => {
    try {
      await appCtx.apiServerClient.album.join(ctx.album._id);

      messageCtx.showSuccess(languageCtx.getStringFromId("youJoinedAnAlbum"));
      ctx.setShow(false);
      // cannot do this since this part of the routing table is not initialized at this point
      navigate(`/app/albums/${ctx.album._id}`);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCancelClicked = async () => {
    ctx.setShow(false);
    if (appCtx.user.get()) {
      navigate("/app/albums");
    } else {
      navigate("/");
    }
  };

  const handleTermsofserviceClicked = (e) => {
    e.preventDefault();

    ctx.pushItem("", (ctx) => (
      <TermsOfServiceControl ctx={ctx} version={termsOfServiceVersion} />
    ));
  };

  const handlePrivacypolicyClicked = (e) => {
    e.preventDefault();

    ctx.pushItem("", (ctx) => (
      <PrivacyPolicyControl ctx={ctx} version={privacyPolicyVersion} />
    ));
  };

  const isFormOk = () => {
    if (!name) return false;
    if (name.trim().length < 1) return false;

    return true;
  };

  if (!ctx.show) return null;

  return (
    <div className="p-4">
      {(!albumUtil.isAlbum(ctx.album) ||
        albumUtil.isUsersFull(ctx.album) ||
        albumUtil.isMediaDeleted(ctx.album)) && (
        <div className="text-center">
          <div className="display-6">
            {languageCtx.getStringFromId("Cannot join the album")}
          </div>
          <div className="mt-3">
            {albumUtil.isUsersFull(ctx.album) && (
              <div>{languageCtx.getStringFromId("Too many invites")}</div>
            )}
            {albumUtil.isMediaDeleted(ctx.album) && (
              <div>{languageCtx.getStringFromId("Album closed")}</div>
            )}
            {!albumUtil.isAlbum(ctx.album) && (
              <div>{languageCtx.getStringFromId("Album does not exist")}</div>
            )}
          </div>
          <div className="mt-4">
            <button className="btn btn-blue" onClick={handleCancelClicked}>
              {languageCtx.getStringFromId("close")}
            </button>
          </div>
        </div>
      )}
      {albumUtil.isAlbum(ctx.album) &&
        !albumUtil.isUsersFull(ctx.album) &&
        !albumUtil.isMediaDeleted(ctx.album) && (
          <div className="text-center">
            <div className="display-6">
              {languageCtx.getStringFromId("Join the album")}
            </div>
            <div className="display-6 fw-bold mt-3">{ctx.album.name}</div>

            <div className="mt-3 cover">
              {ctx.album.cover && (
                <img
                  src={ctx.album.cover.uri}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit:
                      ctx.album.cover?.source === "db" ? "contain" : "cover",
                  }}
                />
              )}
            </div>

            <div className="mt-4">
              {languageCtx.getStringFromId(
                "Help collect photos and videos to this event"
              )}
            </div>
            {ctx.album.isDemo && (
              <div className="mt-4 fst-italic">
                {languageCtx.getStringFromId(
                  "This is a demo album and the content could be deleted at any time"
                )}
              </div>
            )}
            {!userUtil.isUser(appCtx.user.get()) && (
              <div>
                <div className="mt-4">
                  {languageCtx.getStringFromId("What username would you like?")}
                </div>
                <div className="mt-2">
                  <input
                    className="form-control text-center"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div
                  className="mt-2"
                  style={{
                    fontSize: 14,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {languageCtx.getStringFromId("byContinuingYouAcceptOur")}{" "}
                  <a
                    href="#"
                    onClick={handlePrivacypolicyClicked}
                    style={{ color: colors.darkblue }}
                  >
                    {languageCtx.getStringFromId("privacyPolicy")}
                  </a>{" "}
                  {languageCtx.getStringFromId("and our")}{" "}
                  <a
                    href="#"
                    onClick={handleTermsofserviceClicked}
                    style={{ color: colors.darkblue }}
                  >
                    {languageCtx.getStringFromId("termsOfService")}
                  </a>
                </div>
              </div>
            )}
            <div className="mt-3">
              <button className="btn btn-gray" onClick={handleCancelClicked}>
                {languageCtx.getStringFromId("Cancel")}
              </button>
              {!appCtx.user.get() && (
                <button
                  className="btn btn-blue ms-1"
                  onClick={handleSignupAsGuestAndJoinClicked}
                  disabled={!isFormOk()}
                >
                  {languageCtx.getStringFromId("continue")}
                </button>
              )}
              {appCtx.user.get() && (
                <button
                  className="btn btn-blue ms-1"
                  onClick={handleJoinClicked}
                >
                  {languageCtx.getStringFromId("Join now")}
                </button>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default () => {
  const params = useParams();
  const navigate = useNavigate();

  const appCtx = useApp();
  const languageCtx = useLanguage();

  const [show, setShow] = useState(false);
  const [album, setAlbum] = useState();
  const [isWorking, setIsWorking] = useState(false);

  // stack
  const [items, setItems] = useState([
    {
      titleLanguageStringId: languageCtx.getIdFromId("Recomendations"),
      component: (ctx) => <JoinControl ctx={ctx} />,
    },
  ]);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (!params.albumId) {
          return;
        }

        if (!validateUtil.objectId.isValid(params.albumId)) {
          throw new Error("");
        }

        if (appCtx.user.get()) {
          var albums = await appCtx.apiServerClient.album.getAll();

          if (!albums.find((a) => a._id === params.albumId)) {
            const album = await appCtx.apiServerClient.album.getById(
              params.albumId
            );
            setShow(true);
            setAlbum(album);
          } else {
            navigate(`/app/albums/${params.albumId}`);
          }
        } else {
          const album = await appCtx.apiServerClient.album.getById(
            params.albumId
          );
          setShow(true);
          setAlbum(album);
        }
      } catch (ex) {
        setShow(true);
      }
    };

    refresh();
  }, []);

  const setShowProxy = (show) => {
    if (!show) {
      setTimeout(() => reset(), 500);
    }

    // TODO reset
    setShow(show);
  };

  const handleBackClicked = () => {
    if (isWorking) return;

    popItem();
  };

  const pushItem = (titleLanguageStringId, component, onBackClicked) => {
    setItems([...items, { titleLanguageStringId, component, onBackClicked }]);
  };

  const popItem = (count = 1) => {
    const items2 = [...items];
    while (count-- > 0) {
      // cannot remove rootItem
      if (items.length > 1) {
        if (items[items.length - 1].onBackClicked) {
          items[items.length - 1].onBackClicked();
        }
        items2.pop();
      }
    }
    setItems(items2);
  };

  const reset = () => {
    while (items.length > 1) {
      if (items[items.length - 1].onBackClicked) {
        items[items.length - 1].onBackClicked();
      }
      items.pop();
    }

    setItems([...items]);
  };

  if (items.length <= 0) return;

  const currentItem = items[items.length - 1];

  const ctx = {
    // stack
    hasParent: items.length > 1,
    currentItem,
    pushItem,
    popItem,
    items,

    album,
    setAlbum,

    isWorking,
    setIsWorking,

    // modal
    show,
    setShow: setShowProxy,
  };

  return (
    <Modal
      show={show}
      setShow={setShowProxy}
      size="lg"
      closeButton={false}
      backdrop={"static"}
      keyboard={false}
      title={
        <>
          {items.length > 1 ? (
            <span onClick={handleBackClicked} className="clickable">
              <FontAwesomeIcon
                style={{
                  margin: -10,
                  margin: -10,
                  padding: 10,
                  padding: 10,
                }}
                icon={faCaretLeft}
              />
              <span className="ms-2">
                <img
                  style={{ display: "inline", height: "3rem" }}
                  src="/assets/images/fotisima-v2.svg"
                />
              </span>
            </span>
          ) : (
            <span>
              <img
                src="/assets/images/fotisima-v2.svg"
                style={{ height: "3rem" }}
              />
            </span>
          )}
        </>
      }
    >
      {currentItem.component(ctx)}
    </Modal>
  );
};
