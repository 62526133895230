import React from "react";

import settings from "../../../../../../../../utils/settings";

import PayControl from "../../../../../../../controls/pay.control";

import { useApp } from "../../../../../app.context";
import { useUser } from "../../../../user.context";

export default ({ ctx }) => {
  const appCtx = useApp();
  const userCtx = useUser();

  const handleCreateClientSecret = async () => {
    const clientSecret =
      await appCtx.apiServerClient.stripe.createUpgradeAlbumPaymentIntent(
        userCtx.album.get()._id,
        ctx.changePackage.package._id
      );

    return clientSecret;
  };

  return (
    <PayControl
      onCreateClientSecret={handleCreateClientSecret}
      isWorking={ctx.isWorking}
      setIsWorking={ctx.setIsWorking}
      currency={ctx.changePackage.package.currencyCode}
      price={ctx.changePackage.package.priceAmountMicros / 1000000}
      paymentCompletionUrl={window.location.origin + "/app/payment"}
    />
  );
};
