import { useState } from "react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLanguage } from "../../../../../language.context";

import { useApp } from "../../../app.context";

import RecomendationsModal from "../recomendations.button/recomendations.modal/modal";
import { getItems } from "../recomendations.button/recomendations.modal/control";
import { useUser } from "../../user.context";

export default () => {
  const appCtx = useApp();
  const userCtx = useUser();
  const languageCtx = useLanguage();

  const [showRecomendationsModal, setShowRecomendationsModal] = useState(false);

  const handleNotificationsClicked = () => {
    setShowRecomendationsModal(true);
  };

  const items = getItems(languageCtx, appCtx, userCtx);

  return (
    <>
      {items.some((a) => a.visible()) && (
        <button
          className="btn btn-primary-warning d-flex justify-content-center align-items-center"
          style={{ borderRadius: 100, width: 40, height: 40 }}
          onClick={handleNotificationsClicked}
        >
          <FontAwesomeIcon icon={faBell} size="lg" />
        </button>
      )}
      <RecomendationsModal
        show={showRecomendationsModal}
        setShow={setShowRecomendationsModal}
      />
    </>
  );
};
