import { useEffect, useState } from "react";

import { useLanguage } from "../../../../../../../language.context";

import { useApp } from "../../../../../app.context";
import { useException } from "../../../../../../../exception.context";

export default ({ ctx, version }) => {
  const appCtx = useApp();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const [privacyPolicy, setPrivacyPolicy] = useState();

  const refresh = async () => {
    try {
      const privacyPolicy =
        await appCtx.apiServerClient.privacyPolicy.getByVersion(
          version || appCtx.user.get().privacyPolicy.version,
          languageCtx.language.code
        );

      setPrivacyPolicy(privacyPolicy);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  if (!privacyPolicy) return null;

  return (
    <div
      className="p-4 privacypolicy_termsofservice"
      dangerouslySetInnerHTML={{ __html: privacyPolicy.html }}
    ></div>
  );
};
