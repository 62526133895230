import React, { createContext, useState, useEffect, useContext } from "react";

import ApiServerClient from "../../../utils/apiserver.user.client";
import localStorageUtil from "../../../utils/localStorage.util";
import errorUtil from "../../../utils/error.util";
import stringUtil from "../../../utils/string.util";

import { useLanguage } from "../../language.context";
import { useException } from "../../exception.context";
import { useMessage } from "../../message.context";

const apiServerClient = new ApiServerClient();
const appContext = createContext();

export const useApp = () => {
  return useContext(appContext);
};

export const AppContextProvider = ({ children }) => {
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [hideAddToHomeScreenButton, setHideAddToHomeScreenButton] =
    useState(true);
  const [deviceId, setDeviceId] = useState("Browser");
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleException = (ex) => {
      if (errorUtil.isCustomError(ex)) {
        if (ex.error.id === "SERVER_USER_NOT_AUTHENTICATED") {
          signout();
        }
      }
    };

    exceptionCtx.addHandler(handleException);

    return () => exceptionCtx.removeHandler(handleException);
  });

  useEffect(() => {
    if (!user) return;

    messageCtx.showSuccess(
      stringUtil.formatString(
        languageCtx.getStringFromId("Welcome xxx"),
        user.name
      )
    );
  }, [user?._id]);

  const loadHideAddToHomeScreenButton = () => {
    let hideButton = true;
    try {
      hideButton = localStorageUtil.get(`hideAddToHomeScreenButton`) === "1";
    } catch (ex) {}
    return hideButton;
  };

  const saveHideAddToHomeScreenButton = (hideButton) => {
    try {
      localStorageUtil.set(`hideAddToHomeScreenButton`, hideButton ? "1" : "0");
    } catch (ex) {
      console.error(ex);
    }
  };

  const setAndSaveHideAddToHomeScreenButton = (hideButton) => {
    saveHideAddToHomeScreenButton(hideButton);
    setHideAddToHomeScreenButton(hideButton);
  };

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);

        const deviceId = loadDeviceId();

        const hideAddToHomeScreenButton = loadHideAddToHomeScreenButton();

        const token = loadToken();

        const user = await loadUserFromToken(token);

        setAndSaveHideAddToHomeScreenButton(hideAddToHomeScreenButton);
        setAndSaveDeviceId(deviceId);
        setUser(user);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, []);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (user && languageCtx.language) {
          if (languageCtx.language.code !== user.language) {
            const user = await apiServerClient.user.updateLanguage(
              languageCtx.language.code
            );

            setUser(user);
          }
        }
      } catch (ex) {}
    };
    refresh();
  }, [languageCtx.language]);

  const loadDeviceId = () => {
    let uuid;

    try {
      uuid = localStorageUtil.get("deviceId") || "Browser";
    } catch {
      uuid = "Browser";
    }

    return uuid;
  };

  const setAndSaveDeviceId = (deviceId) => {
    saveDeviceId(deviceId);
    setDeviceId(deviceId);
  };

  const saveDeviceId = (deviceId) => {
    try {
      localStorageUtil.set("deviceId", deviceId);
    } catch {}
  };

  const loadToken = () => {
    try {
      const token = localStorageUtil.get("token");

      return token;
    } catch (ex) {
      return null;
    }
  };

  const saveToken = (token) => {
    if (!token) {
      localStorageUtil.remove("token");
    } else {
      localStorageUtil.set("token", token);
    }
  };

  const loadUserFromToken = async (token) => {
    if (!token) {
      return null;
    }

    try {
      const user = await apiServerClient.user.signinByToken(token);

      return user;
    } catch {
      return null;
    }
  };

  // user

  const delete_ = async () => {
    await apiServerClient.user.delete();
    setUser();
  };

  const updateName = async (name) => {
    const user = await apiServerClient.user.updateName(name);
    setUser(user);
  };

  const signup = async (
    name,
    email,
    verificationCode,
    privacyPolicyVersion,
    termsOfServiceVersion
  ) => {
    const user = await apiServerClient.user.signup(
      name,
      email,
      verificationCode,
      languageCtx.language.code,
      privacyPolicyVersion,
      termsOfServiceVersion
    );

    saveToken(user.token);
    setUser(user);

    return user;
  };

  const signupAsGuest = async (
    name,
    privacyPolicyVersion,
    termsOfServiceVersion
  ) => {
    const user = await apiServerClient.user.signupAsGuest(
      name,
      languageCtx.language.code,
      privacyPolicyVersion,
      termsOfServiceVersion
    );

    saveToken(user.token);
    setUser(user);

    return user;
  };

  const registerGuest = async (email, verificationCode, languageCode) => {
    const user2 = await apiServerClient.user.registerGuest(
      user._id,
      email,
      verificationCode,
      languageCode
    );

    saveToken(user2.token);
    setUser(user2);

    return user2;
  };

  const signinByEmailAndVerificationCode = async (email, verificationCode) => {
    const user = await apiServerClient.user.signinByEmailAndVerificationCode(
      email,
      verificationCode
    );

    saveToken(user?.token);
    setUser(user);

    return user;
  };

  const signout = async () => {
    saveToken();
    setUser();
  };

  const initialValue = {
    deviceId,
    apiServerClient,

    hideAddToHomeScreenButton,
    setHideAddToHomeScreenButton: setAndSaveHideAddToHomeScreenButton,

    user: {
      get: () => user,
      signupAsGuest,
      registerGuest,
      signup,
      signinByEmailAndVerificationCode,
      signout,
      updateName,
      delete: delete_,
    },
  };

  if (isLoading) return;

  return (
    <appContext.Provider value={initialValue}>{children}</appContext.Provider>
  );
};
