import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import colors from "../../../../../../../../utils/colors";
import albumUtil from "../../../../../../../../utils/album.util";
import stringUtil from "../../../../../../../../utils/string.util";

import { useLanguage } from "../../../../../../../language.context";
import { useException } from "../../../../../../../exception.context";
import CheckButtons from "../../../../../../../controls/checkbuttons.control";
import SpinnerControl from "../../../../../../../controls/spinner.control";

import { useApp } from "../../../../../app.context";

import PayControl from "./pay.control";
import { useUser } from "../../../../user.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appCtx = useApp();
  const userCtx = useUser();
  const exceptionCtx = useException();

  const [daysLeft, setDaysLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const refresh = async () => {
      setIsLoading(true);

      try {
        if (ctx.changePackage.packages.length > 0) return;

        const store =
          await appCtx.apiServerClient.package.getStoreByIPLocation();

        let packages =
          await appCtx.apiServerClient.package.getAllByCurrencyCode(
            store.currencyCode
          );

        packages.sort((a, b) => a.priceAmountMicros - b.priceAmountMicros);
        let currentDateInAlbum = DateTime.fromISO(
          userCtx.album.get().startDate
        );

        if (currentDateInAlbum < DateTime.now()) {
          currentDateInAlbum = DateTime.now();
        }

        let daysLeft = Math.floor(
          DateTime.fromISO(userCtx.album.get().endDate)
            .diff(currentDateInAlbum, ["days"])
            .toObject().days
        );

        if (daysLeft < 0) daysLeft = 0;

        packages = packages.filter(
          (p) =>
            p.mediaCount >= userCtx.album.get().package.mediaCount &&
            p.inviteCount >= userCtx.album.get().package.inviteCount &&
            p.dayCount >= daysLeft &&
            (p.priceAmountMicros || 0) > 0
        );

        if (!userCtx.album.get().status.started) {
          packages = packages.filter(
            (p) => p.productId !== userCtx.album.get().package.productId
          );
        }

        setDaysLeft(daysLeft);

        ctx.changePackage.setPackages(packages);
        if (packages.length > 0) {
          ctx.changePackage.setPackage(packages[0]);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, []);

  const isFormOk = () => {
    if (!ctx.changePackage.package) return false;
    return true;
  };

  const handleGotoPaymentClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("changePackage"), (ctx) => (
      <PayControl ctx={ctx} />
    ));
  };

  if (isLoading) {
    return (
      <div className="text-center p-4">
        <SpinnerControl />
        <div>{languageCtx.getStringFromId("Loading info")}</div>
      </div>
    );
  }

  if (!ctx.changePackage.package) {
    //return null;
    return (
      <div className="text-center p-4">
        <div>
          {languageCtx.getStringFromId(
            "At this moment the album cannot be changed"
          )}
        </div>
      </div>
    );
  }

  const index = ctx.changePackage.packages.indexOf(ctx.changePackage.package);

  return (
    <div className="text-center p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId("whatNewAlbumPackageDoYouWant")}
      </div>

      <CheckButtons
        items={ctx.changePackage.packages.map((p) => ({
          text: languageCtx.getPackageFromId(p.productId).name,
        }))}
        selectedIndex={index}
        style={{
          marginHorizontal: 20,
          marginTop: 30,
        }}
        setSelectedIndex={(index) =>
          ctx.changePackage.setPackage(ctx.changePackage.packages[index])
        }
      />
      <div className="m-3 p-3 package">
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Invites")}
          </div>
          <div>{ctx.changePackage.package.inviteCount}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Photos/videos")}
          </div>
          <div>{ctx.changePackage.package.mediaCount}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Max minutes per video")}
          </div>
          <div>{ctx.changePackage.package.maxSecondsPerVideo / 60}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("duration")}
          </div>
          <div>
            {stringUtil.formatString(
              languageCtx.getStringFromId("X days"),
              ctx.changePackage.package.dayCount
            )}
          </div>
        </div>

        <div className="fs-4 fw-bold mt-3">
          {ctx.changePackage.package.priceAmountMicros > 0
            ? `${albumUtil.price
                .fromMillis(ctx.changePackage.package.priceAmountMicros)
                .toFixed(2)} ${ctx.changePackage.package.currencyCode}`
            : languageCtx.getStringFromId("free")}
        </div>
      </div>
      <div
        className="mt-3"
        style={{
          color: colors.red,
        }}
      >
        {stringUtil.formatString(
          languageCtx.getStringFromId("currentAlbumSizeIsXWithYMedias"),
          userCtx.album.get().users.length,
          userCtx.album.get().usedPhotoCount +
            userCtx.album.get().usedVideoCount
        )}
      </div>
      <div className="mt-3">
        {`${languageCtx.getStringFromId("tip")}: ${languageCtx.getStringFromId(
          "youCanChangeYourAlbumPackageLaterShouldYouRunOutOfInvitesSpaceOrDays"
        )}`}
      </div>
      <div className="mt-3">
        <button
          className="btn btn-blue mt-4"
          onClick={handleGotoPaymentClicked}
          disabled={!isFormOk()}
        >
          {`${languageCtx.getStringFromId("pay")} - ${albumUtil.price
            .fromMillis(ctx.changePackage.package.priceAmountMicros)
            .toFixed(2)} ${ctx.changePackage.package.currencyCode}`}
        </button>
      </div>
    </div>
  );
};
