import { useRef, useState } from "react";

import validateUtil from "../../../../../../../utils/validate.util";

import { useLanguage } from "../../../../../../language.context";
import { useException } from "../../../../../../exception.context";
import TextInput from "../../../../../../controls/text.input";

import { useApp } from "../../../../app.context";

import ConfirmControl from "./confirm.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appCtx = useApp();
  const exceptionCtx = useException();

  const continueRef = useRef();

  const handleContinueClicked = async () => {
    try {
      await appCtx.apiServerClient.user.sendRegisterGuestVerificationCode(
        ctx.registerUser.email,
        languageCtx.language.code
      );
      ctx.pushItem(languageCtx.getIdFromId("Confirm"), (ctx) => (
        <ConfirmControl ctx={ctx} />
      ));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isEmailFormOk = () => {
    if (!validateUtil.email.isValid(ctx.registerUser.email.trim()))
      return false;

    return true;
  };

  return (
    <div className="p-3">
      <div>
        <div>
          {languageCtx.getStringFromId(
            "Being a registed user has following benefits:"
          )}
        </div>
        <ul className="mt-2">
          <li>
            {languageCtx.getStringFromId(
              "You can use your account on multiple devices"
            )}
          </li>
          <li>
            {languageCtx.getStringFromId("You will get notified upon events")}
          </li>
          <li>
            {languageCtx.getStringFromId("You can create your own album")}
          </li>
        </ul>
        <div>{languageCtx.getStringFromId("Enter you email to register")}</div>
        <div>
          <TextInput
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                continueRef.current.click();
              }
            }}
            value={ctx.registerUser.email}
            onChange={(value) => ctx.registerUser.setEmail(value.toLowerCase())}
          />
        </div>
      </div>
      <div className="mt-3 text-center">
        <button
          ref={continueRef}
          className="btn btn-blue ms-1"
          onClick={handleContinueClicked}
          disabled={!isEmailFormOk()}
        >
          {languageCtx.getStringFromId("continue")}
        </button>
      </div>
    </div>
  );
};
