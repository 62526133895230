import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useException } from "../../../exception.context";
import { useLanguage } from "../../../language.context";

import { useAffiliateApp } from "../app.context";

export default () => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appCtx = useAffiliateApp();

  const inputRef = useRef();
  const btnRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (appCtx.affiliate.get()) {
      navigate("/affiliate/welcome");
    }
  }, []);

  const handleEnterClicked = async () => {
    try {
      await appCtx.affiliate.signin(email, password);

      navigate("../welcome");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="container">
        <div className="fs-1 fw-bold">
          {languageCtx.getStringFromId("Welcome affiliate")}
        </div>
        <div className="fs-2 mt-2">
          {languageCtx.getStringFromId("Please signin")}
        </div>
        <div className="mt-5">
          <div>
            <input
              onKeyDown={(e) => {
                if (e.which === 13) {
                  e.preventDefault();
                  inputRef.current.focus();
                }
              }}
              placeholder={languageCtx.getStringFromId("Email")}
              className="form-control form-control-lg text-center"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-2">
          <div>
            <input
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.which === 13) {
                  e.preventDefault();
                  btnRef.current.click();
                }
              }}
              placeholder={languageCtx.getStringFromId("Password")}
              type="password"
              className="form-control form-control-lg text-center"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-3">
          <button
            className="btn btn-blue"
            onClick={handleEnterClicked}
            ref={btnRef}
          >
            {languageCtx.getStringFromId("Enter")}
          </button>
        </div>
      </div>
    </div>
  );
};
