import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import validateUtil from "../../../../utils/validate.util";

import { useLanguage } from "../../../language.context";
import { useException } from "../../../exception.context";

import { useApp } from "../app.context";

import { useNouser } from "./nouser.context";

export default () => {
  const navigate = useNavigate();

  const nouserCtx = useNouser();
  const appCtx = useApp();
  const exceptionCtx = useException();
  const languageCtx = useLanguage();

  const inputRef = useRef();
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleContinuePressed = async () => {
    try {
      setIsWorking(true);

      const userExists = await appCtx.apiServerClient.user.exists(
        nouserCtx.email.trim()
      );

      if (userExists) {
        nouserCtx.setProcessType("signin");
        await appCtx.apiServerClient.user.sendSigninVerificationCode(
          nouserCtx.email.trim(),
          languageCtx.language.code
        );

        navigate("../enterverificationcode");
      } else {
        nouserCtx.setProcessType("signup");
        navigate("../entername");
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.email.isValid(nouserCtx.email.trim())) return false;

    return true;
  };

  return (
    <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="container">
        <div className="fs-1 fw-bold">
          {languageCtx.getStringFromId("Welcome to Fotisima")}
        </div>
        <div className="fs-2 mt-2">
          {languageCtx.getStringFromId("Please enter your email")}
        </div>
        <input
          ref={inputRef}
          className="form-control mt-4 text-center form-control-lg"
          value={nouserCtx.email}
          onChange={(e) => nouserCtx.setEmail(e.target.value.toLowerCase())}
          onKeyDown={(e) => {
            if (e.which === 13) {
              e.preventDefault();
              document.getElementById("submit").click();
            }
          }}
        />
      </div>

      <div className="mt-4">
        <button
          id="submit"
          type="submit"
          disabled={!isFormOk()}
          className="btn btn-lg btn-black"
          onClick={handleContinuePressed}
        >
          {languageCtx.getStringFromId("Next")}
        </button>
      </div>
    </div>
  );
};
