import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import assetUtil from "../../../utils/asset.util";

import { useLanguage } from "../../language.context";

import { useApp } from "./app.context";
import { useException } from "../../exception.context";

const SLIDE_MS = 20 * 1000;
const UPDATEMEDIASLOW_MS = 1 * 60 * 1000;
const UPDATEMEDIASHIGH_MS = 5 * 60 * 1000;

const FullscreenViewer = ({ mediasRef }) => {
  const languageCtx = useLanguage();

  const showNextTimeoutIdRef = useRef(0);
  const [media, setMedia] = useState();
  const [updateCounter, setUpdateCounter] = useState(0);

  useEffect(() => {
    showNextMedia();

    return () => {
      clearInterval(showNextTimeoutIdRef.current);
    };
  }, [updateCounter]);

  const handleVideoEnded = () => {
    showNextMedia();
  };

  const onChangeMedia = () => {
    setUpdateCounter((d) => d + 1);
  };

  const showNextMedia = () => {
    let media = undefined;
    if (mediasRef.current.length === 0) {
    } else if (mediasRef.current.length === 1) {
      media = mediasRef.current[0];
    } else {
      const getNextMedia = () => {
        while (true) {
          const index = Math.floor(Math.random() * mediasRef.current.length);

          const media2 = mediasRef.current[index];

          if (media?._id !== media2?._id) return media2;
        }
      };

      media = getNextMedia();
    }

    setMedia(media);

    clearTimeout(showNextTimeoutIdRef.current);

    if (assetUtil.isVideo(media?.asset)) {
      // will goto next upon video finished
    } else if (assetUtil.isPhoto(media?.asset)) {
      showNextTimeoutIdRef.current = setTimeout(onChangeMedia, SLIDE_MS);
    } else {
      showNextTimeoutIdRef.current = setTimeout(onChangeMedia, SLIDE_MS);
    }
  };

  return (
    <>
      {!media && (
        <>
          <div>
            <img src="/assets/fulllogo5.png" alt="" style={{ height: 100 }} />
          </div>
          <div className="fs-1 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId(
              "Upload photos to the shared album using Fotisima"
            )}
            <br />
            {languageCtx.getStringFromId("and see them here!")}
          </div>
        </>
      )}
      {media && (
        <>
          {assetUtil.isPhoto(media.asset) && (
            <img
              src={media.reducedFile.uri}
              alt=""
              className="w-100 h-100 object-fit-contain"
            />
          )}
          {assetUtil.isVideo(media.asset) && (
            <video
              autoPlay
              muted
              playsInline
              src={media.reducedFile.uri}
              alt=""
              onEnded={handleVideoEnded}
              className="w-100 h-100 object-fit-contain"
            />
          )}
          <div className="position-absolute bottom-0 left-0 p-3">
            <img
              src="/assets/fulllogo5.png"
              style={{ height: 50, display: "inline" }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default () => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const appCtx = useApp();
  const exceptionCtx = useException();

  const [isFullscreen, setIsFullscreen] = useState(false);
  const updateMediasTimeoutIdRef = useRef(0);
  const mediasRef = useRef([]);
  const [mediasTimestamp, setMediasTimestamp] = useState(
    DateTime.now().toISO()
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);

        const segments = window.location.pathname.split("/");
        const albumId = segments[segments.length - 1];

        const info = await appCtx.apiServerClient.media.getByAlbumId(albumId);

        mediasRef.current = info.medias.filter(
          (m) =>
            m.reducedFile &&
            (assetUtil.isPhoto(m.asset) ||
              (assetUtil.isVideo(m.asset) && assetUtil.duration(m.asset) <= 30))
        );

        setMediasTimestamp(info.timestamp);

        updateMediasTimeoutIdRef.current = setTimeout(
          updateMedias,
          mediasRef.current.length >= 50
            ? UPDATEMEDIASHIGH_MS
            : UPDATEMEDIASLOW_MS
        );
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();

    return () => {
      clearTimeout(updateMediasTimeoutIdRef.current);
    };
  }, []);

  const updateMedias = async () => {
    clearTimeout(updateMediasTimeoutIdRef.current);

    const segments = window.location.pathname.split("/");
    const albumId = segments[segments.length - 1];

    const info = await appCtx.apiServerClient.media.getChangesByAlbumId(
      albumId,
      mediasTimestamp
    );

    if (info.medias.length > 0 || info.deletedMedias.length > 0) {
      for (var media of info.medias) {
        mediasRef.current = mediasRef.current.filter(
          (m) => m._id !== media._id
        );
        mediasRef.current.push(media);
      }

      for (var deletedMedia of info.deletedMedias) {
        mediasRef.current = mediasRef.current.filter(
          (m) => m._id !== deletedMedia._id
        );
      }
    }

    setMediasTimestamp(info.timestamp);

    updateMediasTimeoutIdRef.current = setTimeout(
      updateMedias,
      mediasRef.current.length >= 50 ? UPDATEMEDIASHIGH_MS : UPDATEMEDIASLOW_MS
    );
  };

  useEffect(() => {
    const onFullscreenChanged = ({ matches }) => {
      setIsFullscreen(!!matches);
    };

    const mediaMatch = window.matchMedia("(display-mode: fullscreen)");
    mediaMatch.addEventListener("change", onFullscreenChanged);

    return () => {
      mediaMatch.removeEventListener("change", onFullscreenChanged);
    };
  }, []);

  const handleStartClicked = () => {
    setIsFullscreen(true);
    document.getElementById("fullscreen").requestFullscreen();
  };

  const handleExitClicked = () => {
    // todo
    navigate("./..");
  };

  return (
    <div
      className="position-absolute top-0 bottom-0 left-0 right-0 d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundColor: "black" }}
    >
      {!isFullscreen && (
        <>
          <div>
            <img src="/assets/fulllogo5.png" alt="" style={{ height: 100 }} />
          </div>
          <div className="fs-1 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId("Welcome to Fotisima presentation")}
          </div>
          <div className="fs-3 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId(
              "Photos from your album will be randomly chosen and change every 20 seconds"
            )}
            <br />
            {languageCtx.getStringFromId(
              "Connect your device to a projector or a big screen and enjoy the show"
            )}
          </div>
          <div className="mt-5 btn-toolbar">
            <button
              className="btn btn-blue btn-lg"
              onClick={handleStartClicked}
              disabled={isLoading}
            >
              {languageCtx.getStringFromId("Start")}
            </button>
            <button className="btn btn-gray btn-lg" onClick={handleExitClicked}>
              {languageCtx.getStringFromId("Exit")}
            </button>
          </div>
        </>
      )}
      <div
        id="fullscreen"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        {isFullscreen && <FullscreenViewer mediasRef={mediasRef} />}
      </div>
    </div>
  );
};
